var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "columns",
      class: !!_vm.$slots["optional-controls"]
        ? _vm.optional_controls_class
        : "",
      style: !!_vm.$slots["optional-controls"]
        ? _vm.optional_controls_style
        : "",
    },
    [
      _vm.exportSettings.modal
        ? _c(
            "modal-component",
            {
              on: {
                close: function ($event) {
                  _vm.exportSettings.modal = false
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c("label", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$trans("Eksportuj:"))),
                  ]),
                  _vm._v(" "),
                  _c("input-component", {
                    attrs: {
                      type: "radio",
                      options: [
                        {
                          text: "wszystkie rekordy z bieżącej strony",
                          value: 0,
                        },
                        { text: "wybrane rekordy z bieżącej strony", value: 1 },
                        { text: "wszystkie dostępne rekordy", value: 2 },
                      ],
                    },
                    model: {
                      value: _vm.exportSettings.mode,
                      callback: function ($$v) {
                        _vm.$set(_vm.exportSettings, "mode", $$v)
                      },
                      expression: "exportSettings.mode",
                    },
                  }),
                  _vm._v(" "),
                  _c("input-component", {
                    attrs: {
                      type: "checkbox",
                      label: _vm.$trans("Dołącz nazwy kolumn"),
                    },
                    model: {
                      value: _vm.exportSettings.prependHeaders,
                      callback: function ($$v) {
                        _vm.$set(_vm.exportSettings, "prependHeaders", $$v)
                      },
                      expression: "exportSettings.prependHeaders",
                    },
                  }),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm.exportSettings.mode == 1 &&
                  _vm.exportSettings.rowsSelect.length
                    ? _c("table", { staticClass: "table is-fullwidth" }, [
                        _c("thead", [
                          _c(
                            "tr",
                            [
                              _c("th", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$trans("Eksportuj")) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.exportable_columns,
                                function (column, i) {
                                  return _c("th", { key: i }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(column.label) +
                                        "\n                        "
                                    ),
                                  ])
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.exportSettings.rowsSelect,
                            function (row, i) {
                              return _c(
                                "tr",
                                { key: i },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        "data-label": _vm.$trans("Eksportuj"),
                                      },
                                    },
                                    [
                                      _c("input-component", {
                                        attrs: {
                                          type: "checkbox",
                                          labelClass: "mr-0 pr-0",
                                          label: " ",
                                        },
                                        model: {
                                          value: row.selected,
                                          callback: function ($$v) {
                                            _vm.$set(row, "selected", $$v)
                                          },
                                          expression: "row.selected",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(row.row, function (cell, j) {
                                    return _c(
                                      "td",
                                      {
                                        key: j,
                                        attrs: {
                                          "data-label":
                                            _vm.exportable_columns[j].label,
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(cell) +
                                              "\n                            "
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "notification is-info is-light",
                      staticStyle: {
                        padding: "0.5rem 1rem 0.5rem 1rem",
                        "margin-bottom": "1rem",
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$trans(
                              "Rekordów wybranych do eksportu: %(exportCount)s",
                              { exportCount: _vm.exportCount }
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-danger is-fullwidth",
                          on: {
                            click: function ($event) {
                              _vm.exportSettings.modal = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$trans("Anuluj")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field has-addons" }, [
                        _c(
                          "p",
                          {
                            staticClass: "control",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "button is-success is-fullwidth is-outlined",
                                attrs: { disabled: _vm.exportCount === 0 },
                                on: {
                                  click: function ($event) {
                                    return _vm.executeExport("export.csv")
                                  },
                                },
                              },
                              [
                                _c("icon-component", {
                                  attrs: { icon: "fas fa-file-csv" },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$trans("Pobierz CSV"))),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "control",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button is-success is-fullwidth",
                                attrs: { disabled: _vm.exportCount === 0 },
                                on: {
                                  click: function ($event) {
                                    return _vm.executeExport("export.xlsx")
                                  },
                                },
                              },
                              [
                                _c("icon-component", {
                                  attrs: { icon: "fas fa-file-excel" },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$trans("Pobierz XLSX"))),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.exportSettings.modal &&
      typeof _vm.exportSettings.progress !== "undefined"
        ? _c("modal-component", [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$trans("Proszę czekać, trwa eksportowanie danych...")
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("progress", {
                staticClass: "progress is-success",
                attrs: { max: _vm.exportCount },
                domProps: { value: _vm.exportSettings.progress },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !!_vm.$slots["optional-controls"]
        ? _c(
            "div",
            {
              staticClass: "column is-narrow is-optional-controls",
              class: { "has-search": _vm.display_search },
            },
            [
              _vm.display_search
                ? _c(
                    "div",
                    { staticClass: "control field has-addons is-search" },
                    [
                      _c("div", { staticClass: "control is-expanded" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.temporary_search,
                              expression: "temporary_search",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$trans("Szukaj..."),
                          },
                          domProps: { value: _vm.temporary_search },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.applySearch.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.temporary_search = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-info",
                            on: { click: _vm.applySearch },
                          },
                          [
                            _c("icon-component", {
                              attrs: { icon: "fas fa-search" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.display_refresh
                ? _c(
                    "button",
                    {
                      staticClass:
                        "button has-icons-right is-info is-outlined is-fullwidth",
                      class: _vm.loader ? "is-loading" : "",
                      on: { click: _vm.forceUpdate },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$trans("Odśwież")))]),
                      _vm._v(" "),
                      _c("icon-component", {
                        staticClass: "is-right",
                        attrs: { icon: "fas fa-sync-alt" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.exportable
                ? _c(
                    "button",
                    {
                      staticClass:
                        "button has-icons-right is-link is-outlined is-fullwidth",
                      class: _vm.loader ? "is-loading" : "",
                      attrs: { disabled: _vm.data_count === 0 },
                      on: { click: _vm.startExport },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$trans("Pobierz")))]),
                      _vm._v(" "),
                      _c("icon-component", {
                        staticClass: "is-right",
                        attrs: { icon: "fas fa-file-download" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sortable_columns.length
                ? _c("div", { staticClass: "field is-hidden-desktop" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$trans("Sortowanie:"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "control select is-fullwidth" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.sort_select,
                              expression: "sort_select",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.sort_select = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.sortable_columns, function (col, i) {
                          return _c(
                            "option",
                            { key: i, domProps: { value: col.value } },
                            [_vm._v(_vm._s(col.text))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._t("optional-controls"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "column" }, [
        (_vm.display_search ||
          _vm.display_refresh ||
          _vm.exportable ||
          _vm.sortable_columns.length) &&
        !_vm.$slots["optional-controls"]
          ? _c("div", { staticClass: "columns" }, [
              _vm.display_search
                ? _c("div", { staticClass: "column is-narrow" }, [
                    _c("div", { staticClass: "control field has-addons" }, [
                      _c("div", { staticClass: "control is-expanded" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.temporary_search,
                              expression: "temporary_search",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$trans("Szukaj..."),
                          },
                          domProps: { value: _vm.temporary_search },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.applySearch.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.temporary_search = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-info",
                            on: { click: _vm.applySearch },
                          },
                          [
                            _c("icon-component", {
                              attrs: { icon: "fas fa-search" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.display_refresh
                ? _c("div", { staticClass: "column is-narrow" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button has-icons-right is-info is-outlined",
                        class: _vm.loader ? "is-loading" : "",
                        on: { click: _vm.forceUpdate },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$trans("Odśwież")))]),
                        _vm._v(" "),
                        _c("icon-component", {
                          staticClass: "is-right",
                          attrs: { icon: "fas fa-sync-alt" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.exportable
                ? _c("div", { staticClass: "column is-narrow" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button has-icons-right is-link is-outlined",
                        class: _vm.loader ? "is-loading" : "",
                        attrs: { disabled: _vm.data_count === 0 },
                        on: { click: _vm.startExport },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$trans("Pobierz")))]),
                        _vm._v(" "),
                        _c("icon-component", {
                          staticClass: "is-right",
                          attrs: { icon: "fas fa-file-download" },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.sortable_columns.length
                ? _c(
                    "div",
                    { staticClass: "column is-narrow is-hidden-desktop" },
                    [
                      _c("label", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.$trans("Sortowanie:"))),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "control select is-fullwidth" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sort_select,
                                expression: "sort_select",
                              },
                            ],
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.sort_select = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.sortable_columns, function (col, i) {
                            return _c(
                              "option",
                              { key: i, domProps: { value: col.value } },
                              [_vm._v(_vm._s(col.text))]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "table-container" }, [
          _c(
            "table",
            {
              staticClass: "table is-table-component is-fullwidth",
              class: { "is-striped": _vm.striped },
              staticStyle: { position: "relative" },
            },
            [
              _c("loader-component", { attrs: { show: _vm.loader } }),
              _vm._v(" "),
              _c("thead", { ref: "thead" }, [
                _c(
                  "tr",
                  _vm._l(
                    _vm.visible_columns_thead,
                    function ({ column, colspan }, i) {
                      return _c(
                        "th",
                        {
                          key: i,
                          class: [
                            {
                              "sort-asc":
                                _vm.current_sort == column.sortField &&
                                _vm.current_sort_dir == 1,
                              "sort-desc":
                                _vm.current_sort == column.sortField &&
                                _vm.current_sort_dir == -1,
                              sortable: column.sortable,
                            },
                            column.class,
                          ],
                          attrs: { colspan: colspan },
                          on: {
                            click: function ($event) {
                              return _vm.setSort(column)
                            },
                          },
                        },
                        [
                          column.sortable
                            ? _c("icon-component", {
                                attrs: {
                                  icon:
                                    _vm.current_sort == column.sortField &&
                                    _vm.current_sort_dir == 1
                                      ? "fas fa-sort-amount-down-alt"
                                      : _vm.current_sort == column.sortField &&
                                        _vm.current_sort_dir == -1
                                      ? "fas fa-sort-amount-down"
                                      : "fas fa-sort",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(column.label) },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.visible_rows, function (row, ri) {
                    return _c(
                      "tr",
                      {
                        key: ri,
                        class: [
                          _vm.row_class_generator(row),
                          typeof _vm.visible_rows_links[ri] !== "undefined"
                            ? "row-link"
                            : "",
                          _vm.visible_rows_clickable[ri] ? "clickable-row" : "",
                        ],
                        on: {
                          click: () => {
                            if (_vm.visible_rows_clickable[ri])
                              _vm.$emit("row-click", row)
                          },
                          mousedown: () => {
                            if (_vm.visible_rows_clickable[ri]) {
                              _vm.$emit("row-mousedown", row)
                            }
                          },
                          mouseup: () => {
                            if (_vm.visible_rows_clickable[ri]) {
                              _vm.$emit("row-mouseup", row)
                            }
                          },
                          mousemove: () => {
                            if (_vm.visible_rows_clickable[ri])
                              _vm.$emit("row-mousemove", row)
                          },
                        },
                      },
                      _vm._l(_vm.visible_columns, function (column, ci) {
                        return _c(
                          "td",
                          {
                            key: ci,
                            class: column.class,
                            attrs: {
                              "data-label": `${
                                _vm.current_sort == column.sortField
                                  ? _vm.current_sort_dir == 1
                                    ? "▲ "
                                    : "▼ "
                                  : ""
                              }${column.label}`,
                            },
                          },
                          [
                            _c(
                              typeof _vm.visible_rows_links[ri] !== "undefined"
                                ? _vm.vue_router_links
                                  ? "router-link"
                                  : "a"
                                : "div",
                              _vm._b(
                                { tag: "component" },
                                "component",
                                typeof _vm.visible_rows_links[ri] !==
                                  "undefined"
                                  ? _vm.vue_router_links
                                    ? { to: _vm.visible_rows_links[ri] }
                                    : { href: _vm.visible_rows_links[ri] }
                                  : {},
                                false
                              ),
                              [
                                typeof column.renderFn === "function"
                                  ? _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          column.renderFn(row, {
                                            row_index: ri,
                                            column: column,
                                            column_index: ci,
                                            columns: _vm.normalised_columns,
                                            query: _vm.current_details,
                                          })
                                        ),
                                      },
                                    })
                                  : column.component
                                  ? _c(
                                      column.component.name,
                                      _vm._b(
                                        { tag: "component" },
                                        "component",
                                        typeof column.component.props ===
                                          "object"
                                          ? column.component.props
                                          : typeof column.component.props ===
                                            "function"
                                          ? column.component.props({
                                              row: row,
                                              row_index: ri,
                                              column: column,
                                              column_index: ci,
                                              columns: _vm.normalised_columns,
                                              query: _vm.current_details,
                                            })
                                          : {},
                                        false
                                      )
                                    )
                                  : typeof column.slotName !== "undefined"
                                  ? _vm._t(column.slotName, null, {
                                      row: row,
                                      row_index: ri,
                                      column: column,
                                      column_index: ci,
                                      columns: _vm.normalised_columns,
                                      query: _vm.current_details,
                                    })
                                  : column.translate
                                  ? [
                                      typeof column.field === "string" &&
                                      column.field.indexOf("__") !== -1
                                        ? [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$trans(
                                                    column.field
                                                      .split("__")
                                                      .reduce(
                                                        (a, e) =>
                                                          _vm.getIfIsset(
                                                            () => a[e]
                                                          ),
                                                        row
                                                      )
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$trans(
                                                    column.field === -1
                                                      ? row
                                                      : row[column.field]
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ],
                                    ]
                                  : [
                                      typeof column.field === "string" &&
                                      column.field.indexOf("__") !== -1
                                        ? [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  column.field
                                                    .split("__")
                                                    .reduce(
                                                      (a, e) =>
                                                        _vm.getIfIsset(
                                                          () => a[e]
                                                        ),
                                                      row
                                                    )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  column.field === -1
                                                    ? row
                                                    : row[column.field]
                                                ) +
                                                "\n                                    "
                                            ),
                                          ],
                                    ],
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                  _vm._v(" "),
                  _vm.visible_rows.length == 0 && !_vm.loader
                    ? _c("tr", { staticClass: "no-results" }, [
                        _c(
                          "td",
                          { attrs: { colspan: _vm.visible_columns.length } },
                          [
                            _vm.fetch_error !== false
                              ? _vm._t("error-ocurred", function () {
                                  return [
                                    _vm.fetch_error === 403
                                      ? _c(
                                          "div",
                                          { staticClass: "notification" },
                                          [
                                            _c("icon-component", {
                                              staticClass: "is-centered",
                                              attrs: {
                                                icon: "fas fa-user-lock",
                                                size: "L",
                                              },
                                            }),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$trans(
                                                    "Nie masz uprawnień do tej akcji!"
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "notification" },
                                          [
                                            _c("icon-component", {
                                              staticClass: "is-centered",
                                              attrs: {
                                                icon: "fas fa-exclamation-triangle",
                                                size: "L",
                                              },
                                            }),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$trans(
                                                    "Wystąpił błąd podczas pobierania danych, spróbuj ponownie."
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                })
                              : _vm.current_search != ""
                              ? _vm._t("empty-search", function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "notification" },
                                      [
                                        _c("icon-component", {
                                          staticClass: "is-centered",
                                          attrs: {
                                            icon: "fas fa-search",
                                            size: "L",
                                          },
                                        }),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$trans(
                                                "Twoje wyszukiwanie nie zwróciło żadnych wyników."
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                })
                              : _vm._t("no-data", function () {
                                  return [
                                    _c("div", { staticClass: "notification" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$trans(
                                              "Nie znaleźliśmy żadnych danych do wyświetlenia."
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ]
                                }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.pagination_list.length > 1 || _vm.pagination_informations
          ? _c("div", { staticClass: "columns" }, [
              _vm.pagination_list.length > 1
                ? _c(
                    "div",
                    {
                      staticClass:
                        "column is-one-third is-offset-one-third is-centered",
                    },
                    [
                      _c(
                        "nav",
                        {
                          staticClass: "pagination is-centered",
                          attrs: {
                            role: "navigation",
                            "aria-label": "pagination",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pagination-previous",
                              attrs: { disabled: !_vm.prev_page_active },
                              on: {
                                click: () => {
                                  if (_vm.prev_page_active)
                                    _vm.paginate(_vm.current_page - 1)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$trans("Poprzedni")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "pagination-next",
                              attrs: { disabled: !_vm.next_page_active },
                              on: {
                                click: () => {
                                  if (_vm.next_page_active)
                                    _vm.paginate(_vm.current_page + 1)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$trans("Następny")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "pagination-list is-hidden-touch" },
                            _vm._l(_vm.pagination_list, function (p, i) {
                              return _c("li", { key: i }, [
                                typeof p === "undefined"
                                  ? _c(
                                      "span",
                                      { staticClass: "pagination-ellipsis" },
                                      [_vm._v("…")]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        staticClass: "pagination-link",
                                        class: {
                                          "is-current": _vm.current_page == p,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.paginate(p)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(p))]
                                    ),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pagination_informations
                ? _c(
                    "div",
                    {
                      staticClass: "column is-one-third",
                      class:
                        _vm.pagination_list.length > 1
                          ? ""
                          : "is-offset-two-thirds",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "field is-grouped is-grouped-right",
                          staticStyle: { "line-height": "1.75rem" },
                        },
                        [
                          _c(
                            "span",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$trans(
                                      "rekordy %(start)s-%(stop)s z %(total)s",
                                      {
                                        start: _vm.current_start,
                                        stop: _vm.current_stop,
                                        total: _vm.data_count,
                                      }
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              Array.isArray(_vm.pagination_size_list) &&
                              _vm.pagination_size_list.length > 1
                                ? [
                                    _vm._v(
                                      "\n                            ,\n                            "
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "control select is-small",
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.current_pagination_size,
                                                expression:
                                                  "current_pagination_size",
                                              },
                                            ],
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.current_pagination_size =
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                              },
                                            },
                                          },
                                          _vm._l(
                                            _vm.pagination_size_list,
                                            function (cnt, i) {
                                              return _c("option", { key: i }, [
                                                _vm._v(_vm._s(cnt)),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$trans("rekordów na stronę")
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "\n                            , " +
                                        _vm._s(_vm.current_pagination_size) +
                                        " " +
                                        _vm._s(
                                          _vm.$trans("rekordów na stronę")
                                        ) +
                                        "\n                        "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }