var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "columns",
      staticStyle: { position: "relative", background: "white" },
    },
    [
      _c("loader-component", { attrs: { show: _vm.loader } }),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "modal-component",
            { attrs: { "is-card": true }, on: { close: _vm.closeModal } },
            [
              _c(
                "card-component",
                [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$trans("Add custom series"))),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "block" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$trans(
                          "In order to add custom chart series you must first select device, that will be the source of data and then select which data set from the device to use."
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input-component", {
                    attrs: {
                      label: _vm.$trans("Select device"),
                      placeholder: _vm.$trans("Select device"),
                      type: "autocomplete",
                      options: _vm.$getUrl("backendDevices"),
                      "autocomplete-text-field": "custom_id",
                      "autocomplete-icon": (d) => ({
                        "device-type": d.devicetype.code,
                      }),
                    },
                    model: {
                      value: _vm.modalDevice,
                      callback: function ($$v) {
                        _vm.modalDevice = $$v
                      },
                      expression: "modalDevice",
                    },
                  }),
                  _vm._v(" "),
                  _c("input-component", {
                    attrs: {
                      label: _vm.$trans("Select data set"),
                      type: "select",
                      options: _vm.modalDeviceSets,
                      disabled: !_vm.modalDeviceSets.length,
                      "is-loading": _vm.modalDeviceSetsLoading,
                    },
                    model: {
                      value: _vm.modalSelectedSet,
                      callback: function ($$v) {
                        _vm.modalSelectedSet = $$v
                      },
                      expression: "modalSelectedSet",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "columns", attrs: { id: "alertButtons" } },
                    [
                      _c("div", { staticClass: "column is-half" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-fullwidth is-danger",
                            on: { click: _vm.closeModal },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$trans("Cancel")) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column is-half" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-fullwidth is-success",
                            attrs: {
                              disabled:
                                typeof _vm.modalDevice === "undefined" ||
                                typeof _vm.modalSelectedSet === "undefined",
                            },
                            on: { click: _vm.addCustom },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$trans("Select")) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.rangeModal
        ? _c(
            "modal-component",
            {
              attrs: { "is-card": true },
              on: {
                close: function ($event) {
                  _vm.rangeModal = false
                },
              },
            },
            [
              _c("card-component", [
                _c("h1", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$trans("Select datetime range"))),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "block" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$trans("Select new datetime range for charts data:")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns is-vcentered" }, [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("calendar-component", {
                        attrs: {
                          "is-inline": "",
                          "is-expanded": "",
                          "is-range": "",
                          "allowed-date-range": _vm.allowedDateRange,
                        },
                        model: {
                          value: _vm.rangeModalDates,
                          callback: function ($$v) {
                            _vm.rangeModalDates = $$v
                          },
                          expression: "rangeModalDates",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("input-component", {
                        attrs: {
                          type: "time",
                          label: _vm.$trans("Start time:"),
                          error: _vm.rangeModalTimesErrors.start,
                          "left-icon": "fas fa-clock",
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.selectRange.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.rangeModalTimes.start,
                          callback: function ($$v) {
                            _vm.$set(_vm.rangeModalTimes, "start", $$v)
                          },
                          expression: "rangeModalTimes.start",
                        },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("input-component", {
                        attrs: {
                          type: "time",
                          label: _vm.$trans("Stop time:"),
                          error: _vm.rangeModalTimesErrors.end,
                          "left-icon": "fas fa-clock",
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.selectRange.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.rangeModalTimes.end,
                          callback: function ($$v) {
                            _vm.$set(_vm.rangeModalTimes, "end", $$v)
                          },
                          expression: "rangeModalTimes.end",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                typeof _vm.rangeModalError !== "undefined"
                  ? _c(
                      "div",
                      {
                        staticClass: "notification is-danger fit-content",
                        staticStyle: { "margin-bottom": "1rem" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.rangeModalError) +
                            "\n            "
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "notification is-info is-light fit-content",
                        staticStyle: { "margin-bottom": "1rem" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.rangeModalNotification) +
                            "\n            "
                        ),
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "alertButtons" } },
                  [
                    _c("div", { staticClass: "column is-half" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-fullwidth is-danger",
                          on: {
                            click: function ($event) {
                              _vm.rangeModal = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$trans("Cancel")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column is-half" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-fullwidth is-success",
                          attrs: { disabled: !_vm.rangeModalValid },
                          on: { click: _vm.selectRange },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$trans("Select")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.details
        ? _c("div", { staticClass: "column is-one-quarter" }, [
            _c(
              "button",
              {
                staticClass:
                  "button has-icons-right is-info is-outlined is-fullwidth mb-4",
                class: _vm.loader ? "is-loading" : "",
                on: { click: _vm.forceUpdate },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.$trans("Refresh")))]),
                _vm._v(" "),
                _c("icon-component", {
                  staticClass: "is-right",
                  attrs: { icon: "fas fa-sync-alt" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "columns is-vcentered mb-0 is-mobile" }, [
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$trans("Select series to be displayed:"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column is-narrow" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-small",
                    class: { "is-primary": _vm.allSelected },
                    on: {
                      click: function ($event) {
                        return _vm.selectAll(_vm.allSelected)
                      },
                    },
                  },
                  [
                    _c("icon-component", { attrs: { icon: _vm.allSelected } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$trans("All")))]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _vm._l(
                  Object.keys(_vm.dividedMeasurements),
                  function (group, i) {
                    return [
                      _c("div", {
                        key: "divider_" + i,
                        staticClass: "is-divider",
                        staticStyle: { width: "100%", margin: "1.25rem 0" },
                        attrs: { "data-content": group },
                      }),
                      _vm._v(" "),
                      _vm._l(
                        _vm.dividedMeasurements[group],
                        function (measurement, j) {
                          return _c(
                            "button",
                            {
                              key: i + "_" + j,
                              staticClass:
                                "button is-fullwidth has-icons-left is-multiline",
                              class: {
                                "is-primary has-icons-right":
                                  measurement.selected,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggle(measurement)
                                },
                              },
                            },
                            [
                              _c("icon-component", {
                                staticClass: "is-left",
                                attrs: { icon: measurement.selected },
                              }),
                              _vm._v(" "),
                              measurement.sent_devices.length > 1
                                ? [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$trans(
                                            "%(type)s from %(count)s devices",
                                            {
                                              type: _vm.$trans(
                                                measurement.uom.descr
                                              ),
                                              count:
                                                measurement.sent_devices.length,
                                            }
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "dropdown-component",
                                      {
                                        staticClass: "tag has-round-badge",
                                        class: {
                                          "is-primary": !measurement.selected,
                                        },
                                        attrs: {
                                          "show-arrow": false,
                                          "trigger-component": "span",
                                          "data-badge":
                                            measurement.sent_devices.length,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "icon fa-stack",
                                                    },
                                                    [
                                                      _c("icon-component", {
                                                        attrs: {
                                                          "device-type":
                                                            "beacon",
                                                          wrap: false,
                                                          "data-fa-transform":
                                                            "down-1 right-2 up-1",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("icon-component", {
                                                        attrs: {
                                                          icon: "fas fa-plus",
                                                          wrap: false,
                                                          "data-fa-transform":
                                                            "shrink-4 down-6",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _vm._l(
                                          measurement.sent_devices,
                                          function (device, k) {
                                            return _c(
                                              "a",
                                              {
                                                key: i + "_" + j + "_" + k,
                                                staticClass: "dropdown-item",
                                                class: device.class,
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                                attrs: {
                                                  href: _vm.$getUrl(
                                                    "frontend:devices:single",
                                                    { deviceId: device.id }
                                                  ),
                                                },
                                              },
                                              [
                                                _c("icon-component", {
                                                  attrs: {
                                                    "device-type":
                                                      "device.devicetype.code",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(device.custom_id)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                : [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$trans(
                                            "%(type)s from device %(device)s",
                                            {
                                              type: _vm.$trans(
                                                measurement.uom.descr
                                              ),
                                              device:
                                                measurement.sent_devices[0]
                                                  .custom_id,
                                            }
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                              _vm._v(" "),
                              measurement.selected &&
                              _vm.isset(
                                () =>
                                  _vm.colorsLUT[measurement.uom.code][
                                    measurement.key
                                  ]
                              )
                                ? _c(
                                    "div",
                                    { staticClass: "is-chart-color is-right" },
                                    [
                                      _c("div", {
                                        staticClass: "real",
                                        style: `background-color:${
                                          _vm.colorsLUT[measurement.uom.code][
                                            measurement.key
                                          ]
                                        }; border-color:${
                                          _vm.colorsLUT[measurement.uom.code][
                                            measurement.key
                                          ]
                                        };`,
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        }
                      ),
                    ]
                  }
                ),
              ],
              2
            ),
            _vm._v(" "),
            _vm.customisable
              ? _c(
                  "button",
                  {
                    staticClass: "button is-fullwidth has-icons-left is-link",
                    on: {
                      click: function ($event) {
                        _vm.showModal = true
                      },
                    },
                  },
                  [
                    _c("icon-component", { attrs: { icon: "fas fa-plus" } }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$trans("Add device series"))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column", staticStyle: { position: "relative" } },
        [
          !_vm.loader && !_vm.customisable && !_vm.measurements.data.length
            ? [
                _c("div", { staticClass: "column" }, [
                  _c("div", { staticClass: "columns is-centered" }, [
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c("div", { staticClass: "notification" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$trans(
                                "Sorry, we found no measurement sets to display overe here!"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            : [
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  _vm._l(_vm.chartMeasurements, function (measurement, i) {
                    return _c("measurements-chart", {
                      key: i,
                      attrs: {
                        measurements: measurement,
                        height: _vm.heights,
                        "color-set": Object.values(
                          _vm.colorsLUT[measurement[0].uom.code]
                        ),
                        "x-min": _vm.currentXMin,
                        "x-max": _vm.currentXMax,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                !!_vm.mapMeasurements.length
                  ? _c("map-component", {
                      staticStyle: { position: "relative" },
                      attrs: {
                        height:
                          typeof _vm.heights === "number"
                            ? `${_vm.heights}px;`
                            : _vm.heights,
                        measurements: _vm.mapMeasurements,
                        measurements_colors: _vm.mapColors,
                      },
                    })
                  : _vm._e(),
              ],
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "notification is-info is-light fit-content",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.openRangeModal },
            },
            [
              _c("icon-component", { attrs: { icon: "fas fa-calendar" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.notificationContent))]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }