<template>
    <div class="field" :class="{'has-addons':hasSlotData && !label}">
        <label v-if="hasSlotData && !!label" :for="'input_'+_uid" class="label" :class="labelClass" ref="label">
            <icon-component v-if="labelIcon!==''" :icon="labelIcon"/>
            <span>{{ label }}</span>
        </label>
        <conditional-wrapper :show="hasSlotData && !!label">
            <div class="field" :class="{'has-addons':hasSlotData}">
                <input v-if="type=='checkbox'" :id="'input_'+_uid" class="is-checkradio has-background-color" :class="realInputClass" type="checkbox" :checked="value" v-on="listeners" ref="checkboxInput">
                <label v-if="!hasSlotData && !!label" :for="'input_'+_uid" class="label" :class="labelClass" ref="label">
                    <icon-component v-if="labelIcon!==''" :icon="labelIcon"/>
                    <span>{{ label }}</span>
                </label>
                <template v-if="type=='radio'">
                    <template v-for="(o,i) in options">
                        <input :key="i+'_input'" class="is-checkradio" :class="realInputClass" :id="'input_'+_uid+'_'+i" type="radio" ref="radioInputs" :value="getIfIsset(()=>o.value,o)" :name="'radio_'+_uid" :checked="value==getIfIsset(()=>o.value,o)" v-on="listeners">
                        <label :key="i+'_label'" :for="'input_'+_uid+'_'+i" class="ml-0">{{getIfIsset(()=>o.text,o)}}</label>
                    </template>
                </template>
                <div v-if="!!$slots.before" class="control">
                    <slot name="before"></slot>
                </div>
                <div v-if="type!='checkbox' && type!='radio'" class="control" :class="controlClass">
                    <template v-if="type=='textarea'">
                        <textarea :id="'input_'+_uid" ref="textareaInput" class="textarea" :value="value" v-on="listeners" :placeholder="placeholder" :class="realInputClass" v-bind="bindAttrs"></textarea>
                    </template>
                    <template v-else-if="type=='autocomplete'">
                        <input :id="'input_'+_uid" v-model="autocomplete" ref="autocompleteInput" v-on="listeners" class="input" type="text" :placeholder="placeholder" :class="realInputClass" v-bind="bindAttrs" autocomplete="nope">
                        <icon-component v-if="realLeftIcon!==''"   class="is-left"     :icon="realLeftIcon"/>
                        <icon-component v-if="realRightIcon!==''"  class="is-right"    :icon="realRightIcon"/>
                        <div class="dropdown is-fullwidth" :class="{'is-active':showAutocomplete}" style="display:block">
                            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                <div class="dropdown-content dropdown-scroll">
                                    <loader-component :show="autocompleteIsLoading" />
                                    <div style="height:100px" v-if="autocompleteIsLoading"></div>
                                    <template v-else>
                                        <a v-if="autocompleteAnyAllowed && autocomplete !== ''" class="dropdown-item" @click="selectAutocomplete(autocomplete, true)">
                                            {{$trans(autocompleteAnyAllowedString,{autocomplete})}}
                                        </a>
                                        <template v-for="(o,i) in filteredAutocompleteOptions">
                                            <a v-if="typeof o == 'string'" ref="autocompleteElements" :key="i" class="dropdown-item" :class="{'is-hover': i == autocompleteKeyboardSelect}" @click="selectAutocomplete(o)">
                                                {{o}}
                                            </a>
                                            <a v-else-if="isset(()=>o.text) && isset(()=>o.value)" ref="autocompleteElements" :key="i" class="dropdown-item" :class="{'is-hover': i == autocompleteKeyboardSelect}" @click="selectAutocomplete(o)">
                                                <icon-component v-if="isset(()=>o.icon)" v-bind="o.icon"/>
                                                <span>{{o.text}}</span>
                                            </a>
                                        </template>
                                        <template v-if="autocompleteMoreOptionsString !== false && autocompleteAvailableOptions > autocompleteMaxOptions">
                                            <hr class="dropdown-divider">
                                            <div class="dropdown-item has-text-centered has-background-grey-lighter">
                                                {{$trans(autocompleteMoreOptionsString === true ? 'Dostępne jest więcej rekordów, aby je wyświetlić zawęź wyszukiwanie...' : autocompleteMoreOptionsString)}}
                                            </div>
                                        </template>
                                        <div v-else-if="filteredAutocompleteOptions.length === 0" class="dropdown-item has-text-centered has-background-grey-lighter">
                                            {{$trans('Brak rekordów pasujących do tego zapytania!')}}
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="type=='select'">
                        <div class="select is-fullwidth" :class="realInputClass">
                            <select :id="'input_'+_uid" v-on="listeners" v-bind="bindAttrs" ref="selectInput">
                                <option v-if="!!placeholder && !isset(()=>this.$attrs.multiple)" value="" disabled selected>{{placeholder}}</option>
                                <template v-for="(o,i) in options">
                                    <option v-if="typeof o == 'string'" :key="i" :value="o" :selected="value==o ">{{o}}</option>
                                    <option v-else-if="isset(()=>o.text) && isset(()=>o.value)" :key="i" :value="o.value" :selected="value==o.value" :disabled="getIfIsset(()=>o.disabled, false)">{{o.text}}</option>
                                </template>
                            </select>
                        </div>
                        <icon-component v-if="realLeftIcon!==''" class="is-left" :icon="realLeftIcon"/>
                    </template>
                    <template v-else-if="type=='file'">
                        <div class="file" :class="realInputClass" ref="fileInputWrapper">
                            <label class="file-label">
                                <input class="file-input" type="file" ref="fileInput" v-bind="bindAttrs" v-on="listeners">
                                <span class="file-cta">
                                    <icon-component class="file-icon" v-if="realLeftIcon!==''" :icon="realLeftIcon"></icon-component>
                                    <span v-if="isset(()=>$attrs['button-label'])" class="file-label">
                                        {{$attrs['button-label']}}
                                    </span>
                                    <span v-else class="file-label">
                                        {{$trans("Wybierz plik...")}}
                                    </span>
                                </span>
                                <span v-if="isset(()=>$attrs.filename)" class="file-name">
                                    {{$attrs.filename}}
                                    <icon-component v-if="realRightIcon!==''"  class="is-right"    :icon="realRightIcon"/>
                                </span>
                            </label>
                        </div>
                    </template>
                    <template v-else-if="type=='date' || type=='daterange' || type=='time' || type=='timerange' || type=='datetime' || type=='datetimerange'">
                        <bulma-calendar :type="type.replace('range','')" :range="type.includes('range')" :value="value" v-on="listeners" v-bind="bindAttrs" ref="calendarInput"></bulma-calendar>
                    </template>
                    <template v-else>
                        <input :id="'input_'+_uid" ref="defaultInput" :value="value" v-on="listeners" class="input" :type="type" :placeholder="placeholder" :class="realInputClass" v-bind="bindAttrs">
                        <icon-component v-if="realLeftIcon!==''"   class="is-left"     :icon="realLeftIcon"/>
                        <icon-component v-if="realRightIcon!==''"  class="is-right"    :icon="realRightIcon"/>
                    </template>
                </div>
                <div v-if="!!$slots.default" class="control">
                    <slot></slot>
                </div>
                <p v-if="errorMessage" class="help is-danger">
                    <template v-if="Array.isArray(error)">
                        <span v-for="msg in error" :key="msg">
                            {{ msg }}
                        </span>
                    </template>
                    <template v-else>
                        {{ error }}
                    </template>
                </p>
                <p v-if="successMessage" class="help is-success">
                    <template v-if="Array.isArray(success)">
                        <span v-for="msg in success" :key="msg">
                            {{ msg }}
                        </span>
                    </template>
                    <template v-else>
                        {{ success }}
                    </template>
                </p>
            </div>
        </conditional-wrapper>
    </div>
</template>
<script>
import iconComponent from './icon-component.vue';
import conditionalWrapper from './conditional-wrapper.vue';
import bulmaCalendar from './bulma-calendar.vue';

export default {
    name:'inputField',
    props:{
        type:{
            type:String,
            default:"text",
        },
        placeholder:{
            type:String,
            default:"",
        },
        value:{
            type:[String,Boolean,Array,Number,Date],
            default:"",
        },
        label:{
            type:String,
            default:"",
        },
        labelIcon:{
            type:[String,Boolean],
            default:"",
        },
        error:{
            type:[String,Boolean,Array],
            default:"",
        },
        success:{
            type:[String,Boolean,Array],
            default:"",
        },
        leftIcon:{
            type:[String,Boolean],
            default:"",
        },
        rightIcon:{
            type:[String,Boolean],
            default:"",
        },
        isLoading:{
            type:Boolean,
            default:false,
        },
        options:{
            type:[Array,String],
            default:()=>[],
        },
        autocompleteTextField:{
            type:String,
            default:'name'
        },
        autocompleteValueField:{
            type:String,
            default:'id'
        },
        autocompleteIcon:{
            type:Function,
            default:undefined
        },
        autocompleteMaxOptions:{
            type:Number,
            default:10
        },
        autocompleteAllowAny:{
            type:[Boolean,String],
            default:false
        },
        autocompleteMoreOptionsString:{
            type:[Boolean,String],
            default:false
        },
        inputClass:{
            type:[Object,String],
            default:undefined
        },
        labelClass:{
            type:[Object,String],
            default:undefined
        }
    },
    components:{
        iconComponent,
        conditionalWrapper,
        bulmaCalendar
    },
    computed:{
        realRightIcon(){
            if(this.rightIcon!=='')
                return this.rightIcon;
            if(this.error)
                return 'fas fa-exclamation-triangle';
            if(this.success)
                return 'fas fa-check';
            return '';
        },
        realLeftIcon(){
            if(this.leftIcon!=='')
                return this.leftIcon;
            if(this.type=='file')
                return 'fas fa-upload';
            return '';
        },
        realInputClass(){
            return Object.assign({},{
                'is-success':               this.isSuccess,
                'is-danger':                this.isError,
                'is-multiple':              this.type=='select' && isset(()=>this.$attrs.multiple),
                'is-fullwidth has-name':    this.type=='file' && isset(()=>this.$attrs.filename),
                'is-loading':               (this.type=='file' || this.type=='select')?this.isLoading:false,
                'is-file-drag':             this.type=='file' && getIfIsset(()=>this.$attrs.drag, false)
            }, typeof this.inputClass === 'undefined' ? {} : (typeof this.inputClass === 'string' ? this.inputClass.split(' ').reduce((a,e)=>{
                a[e] = true
                return a
            },{}) : this.inputClass));
        },
        controlClass(){
            return {
                'has-icons-left':           this.realLeftIcon!=='' && this.type!='file',
                'has-icons-right':          this.realRightIcon!=='',
                'is-expanded':              (this.hasSlotData && this.type!='file') || (this.hasSlotData && this.type=='file' && isset(()=>this.$attrs.filename)),
                'is-loading':               (this.type=='file' || this.type=='select')?false:(this.type=='autocomplete'?this.autocompleteIsLoading:this.isLoading),
            };
        },
        hasSlotData(){
            return !!this.$slots.before || !!this.$slots.default;
        },
        listeners(){
            var vmodel_listener={};
            //todo - disabled
            switch(this.type){
                case 'checkbox':{
                    vmodel_listener.change = (e)=>this.$emit('input', e.target.checked);
                } break;
                
                case 'radio':{
                    vmodel_listener.change = (e)=>this.$emit('input', e.target.value);
                } break;

                case 'select':{
                    vmodel_listener.input = (e)=>{
                        if(isset(()=>this.$attrs.multiple)){
                            //TODO - emit real values
                            this.$emit('input', Array.from(e.target.selectedOptions).map((e)=>e.value));
                        }
                        else{
                            const option = this.options[e.target.selectedIndex];
                            this.$emit('input', typeof option === 'string' ? option : getIfIsset(()=>option.value));
                        }
                    };
                } break;
                
                case 'file':{       
                    vmodel_listener.change = (e)=>{
                        this.$emit('files', e.target.files);
                        e.target.value = null;
                    };
                    if(getIfIsset(()=>this.$attrs.drag) && isset(()=>window.addDrag)){
                        this.$nextTick(()=>{
                            window.addDrag(this.$refs.fileInputWrapper, (e)=>{
                                if(e.length > 1 && !getIfIsset(()=>this.$attrs.multiple, false)){
                                    toast($trans('Wgraj tylko jeden plik!'),'error')
                                    return
                                }
                                const accept = getIfIsset(()=>this.$attrs.accept, '').split(',')
                                if(accept.length){
                                    if(!accept.some((extension)=>e[0].name.toLowerCase().endsWith(extension.toLowerCase()))){
                                        toast($trans(`Plik %(filename)s jest złego typu! Wgraj plik odpowiedniego typu.`,{filename: e[0].name}),'error')
                                        return
                                    }
                                }
                                this.$emit('files', e)
                            })
                        })
                    }
                } break;
                
                case 'autocomplete':{
                    vmodel_listener.focus = ()=>{
                        this.showAutocomplete = true;
                        document.addEventListener("click",this.autocompleteCloser,true);
                    }
                    vmodel_listener.blur = ()=>{
                        setTimeout(()=>{
                            if(document.activeElement !== this.$refs.autocompleteInput){
                                this.showAutocomplete = false;
                            }
                        },300);
                    }
                    vmodel_listener.keydown = (e)=>{
                        switch(e.code){
                            case 'Enter':
                                this.selectAutocomplete(this.filteredAutocompleteOptions[this.autocompleteKeyboardSelect]);
                                break;
                            
                            case 'ArrowDown':
                                this.autocompleteKeyboardSelect++;
                                break;
                            
                            case 'ArrowUp':
                                this.autocompleteKeyboardSelect--;
                                break;
                            
                            case 'Tab':
                                this.showAutocomplete = false;
                                break;
                        }
                    }
                    vmodel_listener.input = ()=>{}
                } break;

                case 'date':
                case 'datetime':
                case 'time':
                case 'daterange':
                case 'datetimerange':
                case 'timerange':{
                    vmodel_listener.input = (e)=>this.$emit('input', e);
                } break;
                
                case 'number':{
                    vmodel_listener.input =(e)=>this.$emit('input', parseFloat(e.target.value));
                } break;

                case 'text':
                default:{
                    vmodel_listener.input =(e)=>this.$emit('input', e.target.value);
                }
            }
            delete this.$listeners.input;
            return Object.assign({}, this.$listeners, vmodel_listener);
        },
        isError(){
            return typeof this.error == 'boolean'?this.error:!!this.error;
        },
        errorMessage(){
            return typeof this.error == 'boolean'?false:!!this.error;
        },
        isSuccess(){
            return this.isError?false:(typeof this.success == 'boolean'?this.success:!!this.success);
        },
        successMessage(){
            return typeof this.success == 'boolean'?false:!!this.success;
        },
        bindAttrs(){
            return this.$attrs;
        },
        autocompleteAnyAllowed(){
            return typeof this.autocompleteAllowAny === 'boolean'?this.autocompleteAllowAny:true
        },
        autocompleteAnyAllowedString(){
            return typeof this.autocompleteAllowAny === 'boolean'?'Select "%(autocomplete)s" as an option':this.autocompleteAllowAny
        },
        inputElement(){
            switch(this.type){
                case 'checkbox':{
                    return this.$refs.checkboxInput
                }

                case 'select':{
                    return this.$refs.selectInput
                }

                case 'radio':{
                    return this.$refs.radioInputs
                }

                case 'textarea':{
                    return this.$refs.textareaInput
                }
                
                case 'autocomplete':{
                    return this.$refs.autocompleteInput
                }

                case 'file':{
                    return this.$refs.fileInput
                }

                case 'date':
                case 'daterange':
                case 'time':
                case 'timerange':
                case 'datetime':
                case 'datetimerange':{
                    //TODO?
                    return this.$refs.calendarInput
                }

                default:{
                    return this.$refs.defaultInput
                }
            }
        }
    },
    asyncComputed: {
        filteredAutocompleteOptions:{
            get: async function(){
                if(this.type !== 'autocomplete'){
                    return [];
                }
                if(Array.isArray(this.options)){
                    //TODO - make use of this.autocompleteMaxOptions, remember to update this.autocompleteAvailableOptions
                    return this.options.filter((e)=>{
                        if(typeof e === 'string'){
                            return e.toLowerCase().indexOf(this.autocomplete.toLowerCase()) !== -1;
                        }
                        if(isset(()=>e.text)){
                            return e.text.toLowerCase().indexOf(this.autocomplete.toLowerCase()) !== -1;
                        }
                        return false;
                    })
                }
                this.autocompleteIsLoading = true;
                var url = this.options+(this.options.includes('?')?'&':'?')+'limit='+this.autocompleteMaxOptions;
                if(this.autocomplete!==''){
                    url += '&filter='+this.autocomplete;
                }
                var autocompleteData = (await this.axios.get(url)).data;
                if(Array.isArray(autocompleteData)){
                    this.autocompleteAvailableOptions = autocompleteData.length
                }
                else{
                    this.autocompleteAvailableOptions = autocompleteData.count;
                    autocompleteData = autocompleteData.data
                }
                var ret = autocompleteData.map((e)=>{
                    let textField = this.autocompleteTextField
                    let isSimple = true
                    while(textField.includes('{') && textField.includes('}')){
                        const match = textField.match(/{([^}]+)}/)
                        textField = textField.replace(match[0], e[match[1]])
                        isSimple = false
                    }
                    const ret = {
                        text:   isSimple?e[this.autocompleteTextField]:textField,
                        value:  e[this.autocompleteValueField],
                        obj:    e
                    };
                    if(typeof this.autocompleteIcon === 'function'){
                        ret.icon = this.autocompleteIcon(e);
                    }
                    return ret;
                });
                this.autocompleteIsLoading = false;
                return ret;
            },
            default(){
                return [];
            }
        }
    },
    methods: {
        focus(){
            if(typeof this.inputElement !== 'undefined'){
                if(Array.isArray(this.inputElement) && isset(()=>this.inputElement[0].focus)){
                    this.inputElement[0].focus()
                }
                else if(isset(()=>this.inputElement.focus)){
                    this.inputElement.focus()
                }
            }
        },
        selectAutocomplete(element, force=false){
            this.forcedAutocomplete = undefined;
            if(force && this.autocompleteAnyAllowed && typeof element === 'string'){
                this.$emit('input', element);
                this.showAutocomplete = false;
                this.forcedAutocomplete = element;
            }
            else if(typeof element === 'string'){
                this.$emit('input', element);
                this.showAutocomplete = false;
            }
            else if(isset(()=>element.value)){
                this.$emit('input', element.value);
                if(isset(()=>element.obj)){
                    this.$emit('selected-object', element.obj);
                }
                this.showAutocomplete = false;
            }
        },
        guardAutocompleteSelect(){
            if(this.showAutocomplete){
                if(this.autocompleteKeyboardSelect > (this.filteredAutocompleteOptions.length-1)){
                    this.autocompleteKeyboardSelect = this.filteredAutocompleteOptions.length-1;
                }
                if(this.autocompleteKeyboardSelect < 0){
                    this.autocompleteKeyboardSelect = 0;
                }
                if(isset(()=>this.$refs.autocompleteElements[this.autocompleteKeyboardSelect])){
                    scrollIntoView(this.$refs.autocompleteElements[this.autocompleteKeyboardSelect], {
                        scrollMode: 'if-needed',
                        block: 'nearest'
                    });
                }
            }
        },
        autocompleteCloser(e){
            var clickInside = false;
            if(isset(()=>this.$refs.label) && e.path.includes(this.$refs.label)){
                clickInside = true;
            }
            else if(e.path.includes(this.$refs.autocompleteInput)){
                clickInside = true;
            }
            else if(this.$refs.autocompleteElements.filter((el)=>e.path.includes(el)).length){
                clickInside = true;
            }
            if(!clickInside && document.activeElement !== this.$refs.autocompleteInput){
                this.showAutocomplete = false;
            }
        },
    },
    watch: {
        value:{
            immediate: true,
            async handler(newValue, oldValue){
                if(this.type === 'autocomplete'){
                    if(this.value === '' || typeof this.value === 'undefined'){
                        if(newValue !== oldValue){
                            this.autocomplete = ''
                            this.$emit('input', this.autocomplete)
                        }
                    }
                    else{
                        var arrayToCheck = this.filteredAutocompleteOptions;
                        if(Array.isArray(this.options)){
                            arrayToCheck = this.options;
                        }
                        if(arrayToCheck === null) return;
                        var match = arrayToCheck.filter((e)=>{
                            if(typeof e === 'string'){
                                return this.value === e;
                            }
                            if(isset(()=>e.value) && isset(()=>e.text)){
                                return this.value === e.value;
                            }
                            return false;
                        });
                        if(match.length === 1){
                            this.autocomplete = typeof match[0] === 'string'?match[0]:match[0].text;
                        }
                        else{
                            if(this.autocompleteAnyAllowed && this.autocomplete === this.forcedAutocomplete){
                                return
                            }
                            if(!Array.isArray(this.options) && (Number.isInteger(this.value) || (typeof this.value === 'string' && parseInt(this.value).toString() === this.value))){
                                try{
                                    this.autocompleteIsLoading = true;
                                    var res = (await this.axios.get(this.options+this.value)).data;
                                    this.autocomplete = res.name;
                                    this.autocompleteIsLoading = false;
                                    return;
                                }
                                catch(error){} // eslint-disable-line
                            }
                            this.autocomplete = '';
                            this.$emit('input', this.autocomplete);
                        }
                    }
                }
            }
        },
        'filteredAutocompleteOptions.length': function(){
            this.guardAutocompleteSelect();
        },
        autocompleteKeyboardSelect: function(){
            this.guardAutocompleteSelect();
        },
        showAutocomplete(){
            if(!this.showAutocomplete){
                var arrayToCheck = this.filteredAutocompleteOptions;
                if(Array.isArray(this.options)){
                    arrayToCheck = this.options;
                }
                var match = arrayToCheck.filter((e)=>{
                    if(typeof e === 'string'){
                        return this.autocomplete === e;
                    }
                    if(isset(()=>e.value) && isset(()=>e.text)){
                        return this.autocomplete === e.text;
                    }
                    return false;
                });
                if(this.autocompleteAnyAllowed && match.length === 0 && this.autocomplete !== ''){
                    //pass
                }
                else if(match.length !== 1){
                    this.autocomplete = '';
                    this.$emit('input', this.autocomplete);
                }
                document.removeEventListener("click",this.autocompleteCloser,true);
            }
        },
        options:{
            immediate: true,
            deep: true,
            handler(newOptions, oldOptions){
                if(this.type === 'autocomplete'){
                    if(Array.isArray(this.options)){
                        this.autocompleteIsLoading = !this.options.length;
                    }
                }
                else if(this.type === 'select'){
                    let optionsChanged = true;
                    if(Array.isArray(newOptions) && Array.isArray(oldOptions) && newOptions.length === oldOptions.length){
                        //possibly no real change occured
                        optionsChanged = !newOptions.every((option)=>{
                            const optionValue = getIfIsset(()=>option.value, option)
                            return oldOptions.some((o) => optionValue === getIfIsset(()=>o.value, o))
                        })
                    }
                    if(!optionsChanged){
                        //false watch trigger, ignore
                        return;
                    }
                    if(this.options.length){
                        if(isset(()=>this.$attrs.multiple)){
                            //TODO - logic for select with multiple attr 
                        }
                        else{
                            if(this.options.some((e)=>getIfIsset(()=>e.value, e) === this.value)){
                                //selected option is still available, no need to update our value
                                return;
                            }
                            if(typeof this.options[0] === 'string'){
                                this.$emit('input', this.options[0]);
                            }
                            else if(isset(()=>this.options[0].value)){
                                this.$emit('input', this.options[0].value);
                            }
                            else{
                                this.$emit('input', undefined);
                            }
                        }
                    }
                    else{
                        this.$emit('input', undefined);
                    }
                }
            }
        },
    },
    data(){
        return {
            showAutocomplete:               false,
            autocompleteKeyboardSelect:     0,
            autocomplete:                   '',
            autocompleteIsLoading:          false,
            autocompleteHideTimeout:        undefined,
            autocompleteAvailableOptions:   0,
            forcedAutocomplete:             undefined,
            isWatchImmediate:               true
        }
    },
    mounted(){
        this.isWatchImmediate = false;
    }
}
</script>
<style scoped>
.is-checkradio[type="radio"] + label {
    display: block;
}
.is-file-drag {
    justify-content: center;
    align-items: flex-end;
    min-height: 220px;
}
</style>
<style lang="scss">
.is-drag-upload {
    position: relative;
    min-height: 180px;
    & .is-drag-helper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        opacity: 0.3;
        text-align: center;
        background-image: url(./../../node_modules/@fortawesome/fontawesome-free/svgs/solid/file-download.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 45px;
        & span {
            position: absolute;
            top: calc(50% + 35px);
            width: 100%;
            left: 0px;
            user-select: none;
            &.is-drop {
                display: none;
            }
        }
    }
    &.is-dragged {
        background-color: #effaf3;
        & .is-drag-helper{
            background-image: url(./../../node_modules/@fortawesome/fontawesome-free/svgs/solid/check.svg);
            & span {
                &.is-standard {
                    display: none;
                }
                &.is-drop {
                    display: block;
                }
            }
        }
    }
}
</style>
