<template>
    <div class="map-wrapper" style="padding: 0; position: relative;">
        <loader-component :show="loaderActive" loader_type="local"></loader-component>
        <div ref="mapContainer" :style="`width: ${width}; height: ${height};`"></div>
        <tooltip-component ref="tooltip" trigger="manual" :to-element="tooltipTarget" :theme="tooltipTheme" :arrow="false">
            <div v-html="tooltipData"></div>
        </tooltip-component>
        <a class="button is-round is-dark is-floating" id="zoom-in-button" v-on:click="zoom('in')" :disabled="currentZoom === zoomBounds[1]">
            <span class="icon"><i class="fas fa-plus"></i></span>
        </a>
        <a class="button is-round is-dark is-floating" id="zoom-out-button" v-on:click="zoom('out')" :disabled="currentZoom === zoomBounds[0]">
            <span class="icon"><i class="fas fa-minus"></i></span>
        </a>
    </div>
</template>
<script>
module.exports = {
    props:{
        width:{
            type: String,
            default: '100%',
        },
        height:{
            type: String,
            default: '100vh',
        },
        scrollToZoom:{
            type: Boolean,
            default: false
        },
        loaderActive:{
            type: Boolean,
            default: false
        },
        tooltipData:{
            type: String,
            default: ''
        },
        tooltipTarget:{
            default: undefined
        },
        tooltipTheme:{
            default: 'dark'
        },
        drawable:{
            type: Boolean,
            default: false
        },
        defaultZoom:{
            type: Number,
            default: 13
        },
        zoomBounds:{
            type: Array,
            default: ()=>[4, 18],
            validator: (b)=>{
                return b.length === 2 && b[0] < b[1] && b[0] > 0;
            }
        }
    },
    data(){
        return {
            map:            undefined,
            currentZoom:    this.defaultZoom,
            customMarker:   L.Marker.extend({
                layerId: 'custom-layer-id'
            }),
            customGeoJSON:  L.GeoJSON.extend({
                layerId: 'custom-layer-id'
            }),
            customCircle:   L.Circle.extend({
                layerId: 'custom-layer-id'
            })
        };
    },
    async mounted(){
        this.map = L.map(this.$refs.mapContainer,{
            center:             [52.4082193,16.9335103],
            zoom:               this.defaultZoom,
            zoomControl:        false,
            scrollWheelZoom:    this.scrollToZoom
        });
        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            minZoom: this.zoomBounds[0],
            maxZoom: this.zoomBounds[1],
            pmIgnore: !this.drawable
        }).addTo(this.map);
        setTimeout(()=>{
            this.map.invalidateSize()
            setTimeout(()=>{
                this.map.on('resize', this.newPosition);
                this.map.on('zoomend', ()=>{
                    this.newPosition();
                    this.currentZoom = this.map.getZoom();
                });
                this.map.on('move', this.newPosition);
                this.map.on('moveend', this.newPosition);
            },0);
        },0);
        
        this.$emit('ready');
    },
    methods:{
        newPosition: function(){
            this.$emit('position');
        },

        zoom: function(dir){
            if(dir=='in'){
                this.map.setZoom(this.map.getZoom() + 1);
            }
            else{
                this.map.setZoom(this.map.getZoom() - 1);
            }
        },

        getCurrentViewBounds: function(pad){
            var bounds = this.map.getBounds();
            if(typeof pad !== 'undefined'){
                bounds = bounds.pad(pad);
            }
            return {
                lat_ge: bounds.getSouth(),
                lat_le: bounds.getNorth(),
                lng_ge: bounds.getWest(),
                lng_le: bounds.getEast(),
            }
        },

        getCurrentView(){
            return {
                center: this.map.getCenter(),
                zoom: this.map.getZoom()
            }
        },

        setCurrentView(new_view, animate = false){
            this.map.setView(new_view.center, new_view.zoom, {animate});
        },

        drawGeoJSON(id, geoJSON, mine, layer, url){
            var ret = undefined;
            if(getIfIsset(()=>geoJSON.properties.type,'') === 'Circle'){
                ret = new this.customCircle(geoJSON.properties.center, {radius: geoJSON.properties.radius, className: 'is-path-'+(mine?'mine':'foreign'), layerId: id});
            }
            else{
                ret = new this.customGeoJSON(geoJSON, {className: 'is-path-'+(mine?'mine':'foreign'), layerId: id});
            }
            if(typeof layer === 'undefined'){
                ret.addTo(this.map);
            }
            else{
                ret.addTo(layer);
            }
            if(typeof url !== 'undefined'){
                const a = document.createElementNS("http://www.w3.org/2000/svg", "a");
                a.setAttribute('href', url);
                const parent = getIfIsset(()=>ret._layers[Object.keys(ret._layers)[0]]._path, ret._path).parentNode;
                if(!(parent.tagName === 'a' && parent.getAttribute('href') === url)){
                    if(isset(()=>ret._layers)){
                        for(const layer of Object.keys(ret._layers)){
                            a.appendChild(ret._layers[layer]._path);
                        }
                    }
                    else{
                        a.appendChild(ret._path);
                    }
                    parent.appendChild(a);
                }
            }
            return ret;
        }
    }
}
</script>
<style scoped>
#zoom-in-button {
    position: absolute;
    right: 5px;
    bottom: 75px;
}

#zoom-out-button {
    position: absolute;
    bottom: 25px;
    right: 5px;
}
</style>