var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isset(() => _vm.details.status) && _vm.details.status !== null
    ? _c("span", [_vm._v(_vm._s(_vm.details.status))])
    : _vm.isset(() => _vm.details.orientation) &&
      _vm.details.orientation !== null
    ? _c("span", [_vm._v(_vm._s(_vm.details.orientation))])
    : _vm.isset(() => _vm.details.user) && _vm.details.user !== null
    ? _c(
        "a",
        {
          staticClass: "button is-link is-outlined",
          attrs: {
            href: _vm.$getUrl("frontend:my_company:users:single", {
              userId: _vm.details.user.id,
            }),
          },
        },
        [
          _c("jdenticon-component", {
            attrs: { value: _vm.details.user.email, size: 30 },
          }),
          _vm._v(" "),
          _c("span", [
            _vm._v("\n        " + _vm._s(_vm.details.user.email) + "\n    "),
          ]),
        ],
        1
      )
    : _vm.isset(() => _vm.details.permanent) && _vm.details.permanent !== null
    ? _c(
        "a",
        { staticClass: "button is-link is-outlined", attrs: { href: "#" } },
        [
          _c("jdenticon-component", {
            attrs: { value: _vm.details.permanent.token, size: 30 },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "is-family-monospace" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.details.permanent.token) + "\n    "
            ),
          ]),
        ],
        1
      )
    : _vm.isset(() => _vm.details.params)
    ? _c(
        "span",
        [
          _vm.configVisible
            ? _c(
                "modal-component",
                {
                  attrs: { "is-card": true },
                  on: {
                    close: function ($event) {
                      _vm.configVisible = false
                    },
                  },
                },
                [
                  _c(
                    "card-component",
                    [
                      _c("h1", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$trans("New configuration:"))),
                      ]),
                      _vm._v(" "),
                      _c("configuration-component", {
                        attrs: {
                          "current-device-configuration":
                            _vm.details.params.params,
                          "read-only": true,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "is-divider" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "columns",
                          attrs: { id: "alertButtons" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "column is-half is-offset-half" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "button is-fullwidth is-success",
                                  on: {
                                    click: function ($event) {
                                      _vm.configVisible = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.$trans("OK")) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-link is-outlined",
              on: {
                click: function ($event) {
                  _vm.configVisible = true
                },
              },
            },
            [
              _c("icon-component", { attrs: { icon: "fas fa-cogs" } }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$trans("Show new configuration")) +
                    "\n        "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm.isset(() => _vm.details.reason)
    ? _c("span", [
        _vm._v(
          _vm._s(_vm.$trans("Reset reason:")) + " " + _vm._s(_vm.details.reason)
        ),
      ])
    : _vm.isset(() => _vm.details.quality)
    ? _c("span", [_vm._v(_vm._s(_vm.details.quality))])
    : _vm.details !== null
    ? _c("span", [_vm._v(_vm._s(_vm.details))])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }