var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "tabs", class: _vm.tabsClass }, [
        _c(
          "ul",
          _vm._l(_vm.normalisedTabs, function (tab, i) {
            return _c(
              "li",
              { key: i, class: { "is-active": _vm.selected === i } },
              [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.openTab(i)
                      },
                    },
                  },
                  [
                    tab.icon !== null
                      ? _c("icon-component", { attrs: { icon: tab.icon } })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(tab.label))]),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.normalisedTabs, function (tab, i) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selected === i,
                expression: "selected === i",
              },
            ],
            key: i,
          },
          [_vm._t(tab.slot)],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }