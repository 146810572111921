var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("inner-icon-component", {
    attrs: {
      icon: _vm.realIcon,
      size: _vm.size,
      wrap: _vm.wrap,
      slash: _vm.slash,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }