var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.readOnly
    ? _c(
        "div",
        { staticClass: "columns is-mobile is-multiline" },
        [
          _vm.isset(() => this.deviceConfiguration.temperature) &&
          (_vm.deviceConfiguration.temperature.capabilities.periodic ||
            _vm.deviceConfiguration.temperature.capabilities.thresholds)
            ? [
                _c("div", { staticClass: "column is-full py-0" }, [
                  _c("div", {
                    staticClass: "is-divider my-4",
                    attrs: { "data-content": _vm.$trans("Temperature") },
                  }),
                ]),
                _vm._v(" "),
                _vm.deviceConfiguration.temperature.capabilities.periodic
                  ? [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$trans("Temperature reporting frequency")
                            ) + ":"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column is-half" }, [
                        _vm._v(
                          _vm._s(
                            _vm.timeString(
                              _vm.deviceConfiguration.temperature.configuration
                                .periodic,
                              _vm.$trans(
                                "Periodic temperature reporting is disabled"
                              )
                            )
                          )
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.deviceConfiguration.temperature.capabilities.thresholds
                  ? [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(_vm.$trans("Temperature limits checking")) +
                              ":"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column is-half" }, [
                        _vm._v(
                          _vm._s(
                            _vm.rangeString(
                              this.deviceConfiguration.temperature.configuration
                                .thresholds,
                              "°C",
                              _vm.$trans(
                                "Temperature limits checking is disabled"
                              ),
                              "temperature"
                            )
                          )
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isset(() => this.deviceConfiguration.humidity) &&
          (_vm.deviceConfiguration.humidity.capabilities.periodic ||
            _vm.deviceConfiguration.humidity.capabilities.thresholds)
            ? [
                _c("div", { staticClass: "column is-full py-0" }, [
                  _c("div", {
                    staticClass: "is-divider my-4",
                    attrs: { "data-content": _vm.$trans("Humidity") },
                  }),
                ]),
                _vm._v(" "),
                _vm.deviceConfiguration.humidity.capabilities.periodic
                  ? [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(_vm.$trans("Humidity reporting frequency"))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column is-half" }, [
                        _vm._v(
                          _vm._s(
                            _vm.timeString(
                              _vm.deviceConfiguration.humidity.configuration
                                .periodic,
                              _vm.$trans(
                                "Periodic humidity reporting is disabled"
                              )
                            )
                          )
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.deviceConfiguration.humidity.capabilities.thresholds
                  ? [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(_vm.$trans("Humidity limits checking"))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column is-half" }, [
                        _vm._v(
                          _vm._s(
                            _vm.rangeString(
                              this.deviceConfiguration.humidity.configuration
                                .thresholds,
                              "%",
                              _vm.$trans(
                                "Humidity limits checking is disabled"
                              ),
                              "humidity"
                            )
                          )
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isset(() => this.deviceConfiguration.accelerometer) &&
          (_vm.deviceConfiguration.accelerometer.capabilities.orientation ||
            _vm.deviceConfiguration.accelerometer.capabilities.freefall ||
            _vm.deviceConfiguration.accelerometer.capabilities.hit)
            ? [
                _c("div", { staticClass: "column is-full py-0" }, [
                  _c("div", {
                    staticClass: "is-divider my-4",
                    attrs: { "data-content": _vm.$trans("Acceleration") },
                  }),
                ]),
                _vm._v(" "),
                _vm.deviceConfiguration.accelerometer.capabilities.orientation
                  ? [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$trans("Enable orientation change events")
                            ) + ":"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column is-half" },
                        [
                          _c("icon-component", {
                            attrs: {
                              icon: _vm.deviceConfiguration.accelerometer
                                .configuration.orientation,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.deviceConfiguration.accelerometer.capabilities.freefall
                  ? [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(_vm.$trans("Enable freefall events")) + ":"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column is-half" },
                        [
                          _c("icon-component", {
                            attrs: {
                              icon: _vm.deviceConfiguration.accelerometer
                                .configuration.freefall,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.deviceConfiguration.accelerometer.capabilities.hit
                  ? [
                      _c("div", { staticClass: "column is-half" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$trans("Select hit events")) + ":"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column is-half" }, [
                        _vm._v(
                          _vm._s(
                            _vm.hitEvents.find(
                              (e) =>
                                e.value ===
                                this.deviceConfiguration.accelerometer
                                  .configuration.hit
                            ).text
                          )
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _c(
        "div",
        [
          _c("loader-component", { attrs: { show: _vm.loader } }),
          _vm._v(" "),
          _c("label", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$trans("Device configuration"))),
          ]),
          _vm._v(" "),
          _vm.isPending
            ? _c("div", { staticClass: "notification is-danger is-light" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$trans(
                        "Configuration displayed below is pending, and was not yet propagated to the device."
                      )
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showBattery
            ? [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$trans(
                        "Over here you can see a simple indicator how your current configuration will affect battery lifespan of this device. Full battery indicates, that your configuration is extremly lifespan-friendly. Empty battery, on the other hand - means that this configuration will shorten expected device lifespan."
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns is-centered" }, [
                  _c(
                    "div",
                    { staticClass: "column is-narrow" },
                    [
                      _c("battery-indicator-component", {
                        attrs: { value: _vm.configurationBatteryUsage },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isset(() => this.deviceConfiguration.temperature) &&
          (_vm.deviceConfiguration.temperature.capabilities.periodic ||
            _vm.deviceConfiguration.temperature.capabilities.thresholds)
            ? [
                _c("div", {
                  staticClass: "is-divider",
                  attrs: { "data-content": _vm.$trans("Temperature") },
                }),
                _vm._v(" "),
                _vm.deviceConfiguration.temperature.capabilities.periodic
                  ? [
                      _c("label", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(_vm.$trans("Temperature reporting frequency"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("slider-component", {
                        staticStyle: {
                          "margin-left": "68px",
                          "margin-right": "23px",
                          "margin-top": "1rem",
                        },
                        attrs: {
                          options: _vm.temperaturePeriodicSliderOptions,
                        },
                        on: {
                          value: (v) =>
                            (_vm.deviceConfiguration.temperature.configuration.periodic =
                              parseInt(v)),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "notification is-light fit-content",
                          class:
                            _vm.deviceConfiguration.temperature.configuration
                              .periodic > 0
                              ? "is-info"
                              : "",
                        },
                        [
                          _c("icon-component", {
                            attrs: { icon: "fas fa-clock" },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.timeString(
                                  _vm.deviceConfiguration.temperature
                                    .configuration.periodic,
                                  _vm.$trans(
                                    "Periodic temperature reporting is disabled"
                                  )
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.deviceConfiguration.temperature.capabilities.thresholds
                  ? [
                      _c("label", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(_vm.$trans("Temperature limits checking"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input-component", {
                        attrs: {
                          type: "checkbox",
                          label: _vm.$trans("Enable lower limit"),
                          value: _vm.temperatureThresholdLowerEnabled,
                        },
                        on: {
                          input: (e) =>
                            _vm.$set(
                              this.deviceConfiguration.temperature.configuration
                                .thresholds,
                              0,
                              e ? -10 : false
                            ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input-component", {
                        attrs: {
                          type: "checkbox",
                          label: _vm.$trans("Enable upper limit"),
                          value: _vm.temperatureThresholdUpperEnabled,
                        },
                        on: {
                          input: (e) =>
                            _vm.$set(
                              this.deviceConfiguration.temperature.configuration
                                .thresholds,
                              1,
                              e ? 10 : false
                            ),
                        },
                      }),
                      _vm._v(" "),
                      _vm.temperatureThresholdLowerEnabled ||
                      _vm.temperatureThresholdUpperEnabled
                        ? _c("slider-component", {
                            staticStyle: {
                              "margin-left": "68px",
                              "margin-right": "23px",
                              "margin-top": "1rem",
                            },
                            attrs: {
                              options: _vm.temperatureThresholdsSliderOptions,
                            },
                            on: {
                              value: (v) =>
                                _vm.thresholdsUpdateValue(
                                  "temperature",
                                  _vm.temperatureThresholdLowerEnabled,
                                  v
                                ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "notification is-light fit-content",
                          class:
                            _vm.deviceConfiguration.temperature
                              .lowerBoundEnabled ||
                            _vm.deviceConfiguration.temperature
                              .upperBoundEnabled
                              ? "is-info"
                              : "",
                        },
                        [
                          _c("icon-component", {
                            attrs: { icon: "fas fa-thermometer" },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.rangeString(
                                    this.deviceConfiguration.temperature
                                      .configuration.thresholds,
                                    "°C",
                                    _vm.$trans(
                                      "Temperature limits checking is disabled"
                                    ),
                                    "temperature"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isset(() => this.deviceConfiguration.humidity) &&
          (_vm.deviceConfiguration.humidity.capabilities.periodic ||
            _vm.deviceConfiguration.humidity.capabilities.thresholds)
            ? [
                _c("div", {
                  staticClass: "is-divider",
                  attrs: { "data-content": _vm.$trans("Humidity") },
                }),
                _vm._v(" "),
                _vm.deviceConfiguration.humidity.capabilities.periodic
                  ? [
                      _c("label", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(_vm.$trans("Humidity reporting frequency"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("slider-component", {
                        staticStyle: {
                          "margin-left": "68px",
                          "margin-right": "23px",
                          "margin-top": "1rem",
                        },
                        attrs: { options: _vm.humidityPeriodicSliderOptions },
                        on: {
                          value: (v) =>
                            (_vm.deviceConfiguration.humidity.configuration.periodic =
                              parseInt(v)),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "notification is-light fit-content",
                          class:
                            _vm.deviceConfiguration.humidity.configuration
                              .periodic > 0
                              ? "is-info"
                              : "",
                        },
                        [
                          _c("icon-component", {
                            attrs: { icon: "fas fa-clock" },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.timeString(
                                  _vm.deviceConfiguration.humidity.configuration
                                    .periodic,
                                  _vm.$trans(
                                    "Periodic humidity reporting is disabled"
                                  )
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.deviceConfiguration.humidity.capabilities.thresholds
                  ? [
                      _c("label", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.$trans("Humidity limits checking"))),
                      ]),
                      _vm._v(" "),
                      _c("input-component", {
                        attrs: {
                          type: "checkbox",
                          label: _vm.$trans("Enable lower limit"),
                          value: _vm.humidityThresholdLowerEnabled,
                        },
                        on: {
                          input: (e) =>
                            _vm.$set(
                              this.deviceConfiguration.humidity.configuration
                                .thresholds,
                              0,
                              e ? -10 : false
                            ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input-component", {
                        attrs: {
                          type: "checkbox",
                          label: _vm.$trans("Enable upper limit"),
                          value: _vm.humidityThresholdUpperEnabled,
                        },
                        on: {
                          input: (e) =>
                            _vm.$set(
                              this.deviceConfiguration.humidity.configuration
                                .thresholds,
                              1,
                              e ? 10 : false
                            ),
                        },
                      }),
                      _vm._v(" "),
                      _vm.humidityThresholdLowerEnabled ||
                      _vm.humidityThresholdUpperEnabled
                        ? _c("slider-component", {
                            staticStyle: {
                              "margin-left": "68px",
                              "margin-right": "23px",
                              "margin-top": "1rem",
                            },
                            attrs: {
                              options: _vm.humidityThresholdsSliderOptions,
                            },
                            on: {
                              value: (v) =>
                                _vm.thresholdsUpdateValue(
                                  "humidity",
                                  _vm.humidityThresholdLowerEnabled,
                                  v
                                ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "notification is-light fit-content",
                          class:
                            _vm.deviceConfiguration.humidity
                              .lowerBoundEnabled ||
                            _vm.deviceConfiguration.humidity.upperBoundEnabled
                              ? "is-info"
                              : "",
                        },
                        [
                          _c("icon-component", {
                            attrs: { icon: "fas fa-tint" },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.rangeString(
                                    this.deviceConfiguration.humidity
                                      .configuration.thresholds,
                                    "%",
                                    _vm.$trans(
                                      "Humidity limits checking is disabled"
                                    ),
                                    "humidity"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isset(() => this.deviceConfiguration.accelerometer) &&
          (_vm.deviceConfiguration.accelerometer.capabilities.orientation ||
            _vm.deviceConfiguration.accelerometer.capabilities.freefall ||
            _vm.deviceConfiguration.accelerometer.capabilities.hit)
            ? [
                _c("div", {
                  staticClass: "is-divider",
                  attrs: { "data-content": _vm.$trans("Acceleration") },
                }),
                _vm._v(" "),
                _vm.deviceConfiguration.accelerometer.capabilities.orientation
                  ? _c("input-component", {
                      attrs: {
                        type: "checkbox",
                        label: _vm.$trans("Enable orientation change events"),
                      },
                      model: {
                        value:
                          _vm.deviceConfiguration.accelerometer.configuration
                            .orientation,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.deviceConfiguration.accelerometer.configuration,
                            "orientation",
                            $$v
                          )
                        },
                        expression:
                          "deviceConfiguration.accelerometer.configuration.orientation",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.deviceConfiguration.accelerometer.capabilities.freefall
                  ? _c("input-component", {
                      attrs: {
                        type: "checkbox",
                        label: _vm.$trans("Enable freefall events"),
                      },
                      model: {
                        value:
                          _vm.deviceConfiguration.accelerometer.configuration
                            .freefall,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.deviceConfiguration.accelerometer.configuration,
                            "freefall",
                            $$v
                          )
                        },
                        expression:
                          "deviceConfiguration.accelerometer.configuration.freefall",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.deviceConfiguration.accelerometer.capabilities.hit
                  ? _c("input-component", {
                      attrs: {
                        type: "select",
                        label: _vm.$trans("Select hit events"),
                        options: _vm.hitEvents,
                      },
                      model: {
                        value:
                          _vm.deviceConfiguration.accelerometer.configuration
                            .hit,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.deviceConfiguration.accelerometer.configuration,
                            "hit",
                            $$v
                          )
                        },
                        expression:
                          "deviceConfiguration.accelerometer.configuration.hit",
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isset(() => this.deviceConfiguration.airquality)
            ? [
                _c("div", {
                  staticClass: "is-divider",
                  attrs: { "data-content": _vm.$trans("Air quality") },
                }),
                _vm._v(" "),
                _c("table", { staticClass: "table is-fullwidth" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c("td"),
                        _vm._v(" "),
                        _vm._l(_vm.airqualityColumns, function (column, i) {
                          return _c("th", { key: i }, [_vm._v(_vm._s(column))])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.airqualityRows, function (entry, i) {
                      return _c(
                        "tr",
                        { key: i },
                        [
                          _c("th", { staticClass: "is-narrow" }, [
                            _vm._v(_vm._s(entry[0])),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.airqualityColumns, function (column, j) {
                            return _c(
                              "td",
                              { key: j },
                              [
                                typeof entry[1][column] !== "undefined"
                                  ? _c("input-component", {
                                      attrs: { required: "", type: "number" },
                                      model: {
                                        value:
                                          _vm.deviceConfiguration.airquality
                                            .configuration[entry[0]][column],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.deviceConfiguration.airquality
                                              .configuration[entry[0]],
                                            column,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "deviceConfiguration.airquality.configuration[entry[0]][column]",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button is-primary is-fullwidth",
              class: _vm.loader ? "is-loading" : "",
              staticStyle: { "margin-top": "1em" },
              attrs: { disabled: !_vm.hasChanges },
              on: { click: _vm.sendConfiguration },
            },
            [_vm._v(_vm._s(_vm.$trans("Save configuration")))]
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }