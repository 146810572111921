<template>
    <div class="columns" style="position:relative; background:white;">
        <loader-component :show="loader"></loader-component>
        <modal-component v-if="showModal" :is-card="true" @close="closeModal">
            <card-component>
                <h1 class="title">{{ $trans("Add custom series") }}</h1>
                <p class="block">{{ $trans("In order to add custom chart series you must first select device, that will be the source of data and then select which data set from the device to use.") }}</p>

                <input-component :label="$trans('Select device')" :placeholder="$trans('Select device')" type="autocomplete" v-model="modalDevice" :options="$getUrl('backendDevices')" autocomplete-text-field="custom_id" :autocomplete-icon="(d)=>({'device-type':d.devicetype.code})"></input-component>
                <input-component :label="$trans('Select data set')" type="select" v-model="modalSelectedSet" :options="modalDeviceSets" :disabled="!modalDeviceSets.length" :is-loading="modalDeviceSetsLoading"></input-component>

                <div class="columns" id="alertButtons">
                    <div class="column is-half">
                        <button @click="closeModal" class="button is-fullwidth is-danger">
                            {{ $trans("Cancel") }}
                        </button>
                    </div>
                    <div class="column is-half">
                        <button @click="addCustom" class="button is-fullwidth is-success" :disabled="typeof modalDevice === 'undefined' || typeof modalSelectedSet === 'undefined'">
                            {{ $trans("Select") }}
                        </button>
                    </div>
                </div>
            </card-component>
        </modal-component>
        <modal-component v-if="rangeModal" :is-card="true" @close="rangeModal=false">
            <card-component>
                <h1 class="title">{{ $trans("Select datetime range") }}</h1>
                <p class="block">{{ $trans("Select new datetime range for charts data:") }}</p>

                <div class="columns is-vcentered">
                    <div class="column">
                        <calendar-component is-inline is-expanded is-range v-model="rangeModalDates" :allowed-date-range="allowedDateRange"></calendar-component>
                    </div>
                    <div class="column">
                        <input-component type="time" v-model="rangeModalTimes.start" :label="$trans('Start time:')" :error="rangeModalTimesErrors.start"    left-icon="fas fa-clock" @keyup.enter="selectRange"/>
                        <br>
                        <input-component type="time" v-model="rangeModalTimes.end"   :label="$trans('Stop time:')"  :error="rangeModalTimesErrors.end"      left-icon="fas fa-clock" @keyup.enter="selectRange"/>
                    </div>
                </div>

                <div v-if="typeof rangeModalError !== 'undefined'" class="notification is-danger fit-content" style="margin-bottom: 1rem;">
                    {{rangeModalError}}
                </div>
                <div v-else class="notification is-info is-light fit-content" style="margin-bottom: 1rem;">
                    {{rangeModalNotification}}
                </div>

                <div class="columns" id="alertButtons">
                    <div class="column is-half">
                        <button @click="rangeModal=false" class="button is-fullwidth is-danger">
                            {{ $trans("Cancel") }}
                        </button>
                    </div>
                    <div class="column is-half">
                        <button @click="selectRange" class="button is-fullwidth is-success" :disabled="!rangeModalValid">
                            {{ $trans("Select") }}
                        </button>
                    </div>
                </div>
            </card-component>
        </modal-component>

        <div class="column is-one-quarter" v-if="details">
            <button class="button has-icons-right is-info is-outlined is-fullwidth mb-4" :class="loader?'is-loading':''" @click="forceUpdate">
                <span>{{$trans('Refresh')}}</span>
                <icon-component icon="fas fa-sync-alt" class="is-right"></icon-component>
            </button>
            <div class="columns is-vcentered mb-0 is-mobile">
                
                <div class="column">
                    <label class="label">{{ $trans("Select series to be displayed:") }}</label>
                </div>
                <div class="column is-narrow">
                    <button class="button is-small" :class="{'is-primary':allSelected}" @click="selectAll(allSelected)">
                        <icon-component :icon="allSelected"></icon-component>
                        <span>{{ $trans("All") }}</span>
                    </button>
                </div>
            </div>
            <div class="buttons">
                <template v-for="(group, i) in Object.keys(dividedMeasurements)">
                    <div class="is-divider" :key="'divider_'+i" :data-content="group" style="width: 100%; margin: 1.25rem 0;"></div>
                    <button v-for="(measurement, j) in dividedMeasurements[group]" :key="i+'_'+j" class="button is-fullwidth has-icons-left is-multiline" :class="{'is-primary has-icons-right':measurement.selected}" @click="toggle(measurement)">
                        <icon-component :icon="measurement.selected" class="is-left"></icon-component>
                        <template v-if="measurement.sent_devices.length>1">
                            <span class="mr-2">{{ $trans("%(type)s from %(count)s devices",{type:$trans(measurement.uom.descr), count:measurement.sent_devices.length}) }}</span>
                            <dropdown-component class="tag has-round-badge" :class="{'is-primary':!measurement.selected}" :show-arrow="false" trigger-component="span" :data-badge="measurement.sent_devices.length">
                                <template v-slot:button>
                                    <span class="icon fa-stack">
                                        <icon-component device-type="beacon" :wrap="false" data-fa-transform="down-1 right-2 up-1"></icon-component>
                                        <icon-component icon="fas fa-plus" :wrap="false" data-fa-transform="shrink-4 down-6"></icon-component>
                                    </span>
                                </template>
                                <a v-for="(device, k) in measurement.sent_devices" :key="i+'_'+j+'_'+k" class="dropdown-item" :class="device.class" :href="$getUrl('frontend:devices:single',{deviceId: device.id})" style="text-align:left">
                                    <icon-component device-type="device.devicetype.code"></icon-component>
                                    <span>{{device.custom_id}}</span>
                                </a>
                            </dropdown-component>
                        </template>
                        <template v-else>
                            <span>{{ $trans("%(type)s from device %(device)s",{type:$trans(measurement.uom.descr), device:measurement.sent_devices[0].custom_id}) }}</span>
                        </template>
                        <div v-if="measurement.selected && isset(()=>colorsLUT[measurement.uom.code][measurement.key])" class="is-chart-color is-right">
                            <div class="real" :style="`background-color:${colorsLUT[measurement.uom.code][measurement.key]}; border-color:${colorsLUT[measurement.uom.code][measurement.key]};`"></div>
                        </div>
                    </button>
                </template>
            </div>
            <button class="button is-fullwidth has-icons-left is-link" v-if="customisable" @click="showModal = true">
                <icon-component icon="fas fa-plus"></icon-component>
                <span>{{ $trans("Add device series") }}</span>
            </button>
        </div>
        <div class="column" style="position:relative">
            <template v-if="!loader && !customisable && !measurements.data.length">
                <div class="column">
                    <div class="columns is-centered">
                        <div class="column is-narrow">
                            <div class="notification">
                                {{$trans('Sorry, we found no measurement sets to display overe here!')}}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div style="position: relative;">
                    <measurements-chart v-for="(measurement, i) in chartMeasurements" :key="i" :measurements="measurement" :height="heights" :color-set="Object.values(colorsLUT[measurement[0].uom.code])" :x-min="currentXMin" :x-max="currentXMax"></measurements-chart>
                </div>
                <map-component v-if="!!mapMeasurements.length" :height="typeof heights === 'number'?`${heights}px;`:heights" style="position: relative;" :measurements="mapMeasurements" :measurements_colors="mapColors"></map-component>
                <!--<slider-component style="margin-left: 68px; margin-right: 23px; margin-top: 1rem;" :options="sliderOptions" @value="xUpdate" v-if="sliderOptions.range.max !== sliderOptions.range.min"></slider-component>-->
            </template>
            <div class="notification is-info is-light fit-content" style="cursor: pointer;" @click="openRangeModal">
                <icon-component icon="fas fa-calendar" />
                <span>{{ notificationContent }}</span>
            </div>
        </div>
    </div>
</template>
<script>
module.exports = {
    name:'measurementsComponent',
    props:{
        name:{
            type: String,
            default: undefined
        },
        device:{
            type: Number,
            default: undefined,
        },
        shipment:{
            type: Number,
            default: undefined,
        },
        unit:{
            type: String,
            default: undefined,
        },
        heights:{
            type: [String, Number],
            default: 300
        },
        customisable:{
            type: Boolean,
            default: false
        },
        selectedLimit:{
            type: Number,
            default: 10
        },
        skipMap:{
            type: Boolean,
            default: false
        },
    },
    async mounted(){
        await waitUntil(()=>isset(()=>django.currentLang));
        var selectAll = !this.prefixedName;
        if(!!this.prefixedName){
            try{
                const settings = (await axios.get($getUrl('backendCustomisedGet',{name:this.prefixedName}))).data.value;
                Vue.set(this, 'selected', settings.selected);
                Vue.set(this, 'list', settings.list);
            }
            catch(e){
                console.error(e);
                selectAll = true;
            }
        }
        this.measurements = await this.getMeasurements();
        if(selectAll){
            await this.selectAll();
        }
        this.loader = false;
        this.initial = false;
    },
    data(){
        return {
            showModal:              false,
            modalDevice:            undefined,
            modalDeviceSets:        [],
            modalDeviceSetsLoading: false,
            modalSelectedSet:       undefined,

            rangeModal:             false,
            rangeModalDates:        undefined,
            rangeModalError:        undefined,
            rangeModalTimes:{
                start:              undefined,
                end:                undefined
            },
            rangeModalTimesErrors:{
                start:              undefined,
                end:                undefined
            },

            loader:         true,
            initial:        true,
            details:        true,

            measurements: {
                date_from:          0,
                date_to:            0,
                max_date_span:      1,
                minimum_timestamp:  0,
                maximum_timestamp:  0,
                data:               []
            },
            currentXMin:    Number.POSITIVE_INFINITY,
            currentXMax:    Number.NEGATIVE_INFINITY,
            sliderOptions:  {
                start: [0, 0],
                connect: true,
                behaviour: "drag",
                step: 1,
                range: {
                    'min': 0,
                    'max': 0
                },
                limit: 1
            },
            selected:       {},
            list:           [],
            colorSet:       ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC'],
        };
    },
    computed:{
        timeRange(){
            return [this.currentXMin, this.currentXMax];
        },
        rangeModalNotification(){
            if(isset(()=>this.rangeModalDates.start) && isset(()=>this.rangeModalDates.end)){
                return renderTimestamp(this.rangeModalDates.start/1000,"SHORT_DATETIME_FORMAT") + ' - ' + renderTimestamp(this.rangeModalDates.end/1000,"SHORT_DATETIME_FORMAT");
            }
            return '??? - ???';
        },
        rangeModalValid(){
            return typeof this.rangeModalError === 'undefined' && typeof this.rangeModalTimesErrors.start === 'undefined' && typeof this.rangeModalTimesErrors.end === 'undefined' && isset(()=>this.rangeModalDates.start) && this.rangeModalDates.start instanceof Date && isset(()=>this.rangeModalDates.end) && this.rangeModalDates.end instanceof Date;
        },
        notificationContent(){
            if(this.currentXMin !== Number.POSITIVE_INFINITY && this.currentXMax !== Number.NEGATIVE_INFINITY){
                return renderTimestamp(this.currentXMin,"SHORT_DATETIME_FORMAT") + ' - ' + renderTimestamp(this.currentXMax,"SHORT_DATETIME_FORMAT");
            }
            return '??? - ???';
        },
        prefixedName(){
            if(typeof this.name === 'undefined'){
                return false;
            }
            return 'measurements_settings_'+this.name;
        },
        selectableMeasurements(){
            var ret = this.measurements.data.map((m, i)=>{
                Vue.set(m, 'key', isset(()=>m.aggregation_index)?'agg_'+m.aggregation_index:m.sent_devices[0].id);
                Vue.set(m, 'selected', isset(()=>this.selected[m.uom.code]) && this.selected[m.uom.code].includes(m.key));
                const firstDevShipment = getIfIsset(()=>m.sent_devices[0].current_shipment.id, null);
                Vue.set(m, 'shipment', m.sent_devices.every((e)=>getIfIsset(()=>e.current_shipment.id, null) === firstDevShipment)?firstDevShipment:null);
                return m;
            });
            if(this.skipMap){
                return ret.filter((m)=>m.uom.code !== 'location');
            }
            return ret;
        },
        dividedMeasurements(){
            return this.selectableMeasurements.reduce((a, m)=>{
                const this_group = m.shipment === null?(m.sent_devices.every((e)=>e.current_shipment === null)?$trans('Devices without shipment'):$trans("Aggregated measurements")):(m.sent_devices[0].current_shipment.name + ' [#' + m.sent_devices[0].current_shipment.id + ']');
                if(!isset(()=>a[this_group])){
                    Vue.set(a,this_group,[]);
                }
                a[this_group].push(m);
                return a;
            },{});
        },
        visibleMeasurements(){
            return this.selectableMeasurements.filter((m)=>m.selected === true);
        },
        separatedMeasurements(){
            const ret = {};
            var xMin = Number.POSITIVE_INFINITY;
            var xMax = Number.NEGATIVE_INFINITY;
            for(const measurement of this.visibleMeasurements){
                if(!isset(()=>ret[measurement.uom.code])){
                    ret[measurement.uom.code] = [];
                }
                ret[measurement.uom.code].push(measurement);
                xMin = Math.min(xMin, measurement.measurements[measurement.measurements.length-1].timestamp);
                xMax = Math.max(xMax, measurement.measurements[0].timestamp);
            }
            if(!!this.selectedCount && (this.currentXMin === Number.POSITIVE_INFINITY || this.currentXMax === Number.NEGATIVE_INFINITY)){
                this.currentXMin = Math.max(this.measurements.date_from, this.measurements.minimum_timestamp, xMin);
                this.currentXMax = Math.min(this.measurements.date_to, this.measurements.maximum_timestamp, xMax);
                this.updateSlider();
            }
            return ret;
        },
        colorsLUT(){
            const ret = {};
            var i = 0;
            for(const measurements of Object.values(this.separatedMeasurements)){
                if(measurements.length){
                    Vue.set(ret, measurements[0].uom.code, {});
                    for(const measurement of measurements){
                        Vue.set(ret[measurement.uom.code], measurement.key, this.colorSet[i]);
                        i++;
                    }
                }
            }
            return ret;
        },
        chartMeasurements(){
            return Object.values(Object.keys(this.separatedMeasurements).filter((k)=> k !== 'location').reduce((o, k)=>{
                o[k] = this.separatedMeasurements[k];
                return o;
            },{}));
        },
        mapMeasurements(){
            const ret = JSON.parse(JSON.stringify(Object.values(Object.keys(this.separatedMeasurements).filter((k)=> k === 'location').reduce((o, k)=>{
                o[k] = this.separatedMeasurements[k];
                return o;
            },{}))));
            for(const i in ret){
                for(const j in ret[i]){
                    ret[i][j].measurements = ret[i][j].measurements.filter((m)=> m.timestamp >= this.currentXMin && m.timestamp <= this.currentXMax);
                }
            }
            return ret;
        },
        mapColors(){
            return Object.values(this.colorsLUT['location']);
        },
        selectedCount(){
            var ret = 0;
            const selectedKeys = Object.keys(this.selected);
            for(const selected of selectedKeys){
                ret += this.selected[selected].length;
            }
            return ret;
        },
        allSelected(){
            return this.selectedCount === this.measurements.data.length && !!this.measurements.data.length;
        },
        allowedDateRange(){
            if(this.measurements.minimum_timestamp !== null && this.measurements.maximum_timestamp !== null){
                return [this.measurements.minimum_timestamp*1000, this.measurements.maximum_timestamp*1000];
            }
            return [undefined, undefined];
        }
    },
    methods:{
        selectRange(){
            if(this.rangeModalValid){
                this.currentXMin = this.rangeModalDates.start.getTime()/1000;
                this.currentXMax = this.rangeModalDates.end.getTime()/1000;
                this.updateSlider();
                this.rangeModal = false;
            }
        },
        openRangeModal(){
            if(this.currentXMin !== Number.POSITIVE_INFINITY && this.currentXMax !== Number.NEGATIVE_INFINITY){
                this.rangeModalDates = {
                    start:  new Date(this.currentXMin*1000),
                    end:   new Date(this.currentXMax*1000)
                };
            }
            else{
                this.rangeModalDates = undefined;
            }
            this.rangeModal = true;
        },
        getQueryUrl(params){
            if(typeof this.device !== 'undefined'){
                return $getUrl("backendDevicesSingleTelemetry",{deviceId: this.device}, params);
            }
            if(typeof this.shipment !== 'undefined'){
                return $getUrl("backendShipmentsSingleTelemetry",{shipmentId: this.shipment}, params);
            }
            if(typeof this.unit !== 'undefined'){
                return $getUrl("backendUnitsSingleTelemetry",{unitCode: this.unit}, params);
            }
        },
        xUpdate(values){
            this.currentXMin = parseInt(values[0]);
            this.currentXMax = parseInt(values[1]);
        },
        selectAll(deselect=false){
            for(const measurement of this.selectableMeasurements){
                if((deselect && measurement.selected) || (!deselect && !measurement.selected)){
                    this.toggle(measurement);
                }
            }
            return;
        },
        async updateSelected(){
            if(!!this.prefixedName){
                try{
                    await axios.post($getUrl('backendCustomisedSet'),{name:this.prefixedName,value:{list:this.list, selected:this.selected}});
                }
                catch(e){
                    console.error(e);
                }
            }
        },
        toggle(measurement){
            if(isset(()=>this.selected[measurement.uom.code]) && this.selected[measurement.uom.code].includes(measurement.key)){
                this.selected[measurement.uom.code].splice(this.selected[measurement.uom.code].indexOf(measurement.key),1);
            }
            else if(this.selectedCount < this.selectedLimit){
                if(!isset(()=>this.selected[measurement.uom.code])){
                    Vue.set(this.selected, measurement.uom.code, []);
                }
                this.selected[measurement.uom.code].push(measurement.key);
            }
        },
        closeModal(){
            this.showModal = false;
            this.modalDevice = undefined;
            this.modalDeviceSets = [];
            this.modalDeviceSetsLoading = false;
            this.modalSelectedSet = undefined;
        },
        async addCustom(){
            if(!this.measurements.some((m)=> m.uom.code === this.modalSelectedSet && m.device_sent_id === this.modalDevice)){
                if(await this.addNewSet(this.modalSelectedSet,this.modalDevice)){
                    console.warn('todo - add this set to list, so saving makes sense');
                    this.toggle(this.measurements[this.measurements.length-1]);
                }
            }
            else{
                toast($trans("Selected data set is already present on the list!"),'warning');
            }
            this.closeModal();
        },
        async addNewSet(uom, deviceId){
            var success = true;
            this.loader = true;
            try{
                //TODO - this will fail!
                this.measurements = this.measurements.concat(await this.getMeasurements(/* undefined, [[uom,[deviceId]]] */));
            }
            catch(e){
                console.error(e);
                toast($trans("Unfortunately an error ocurred while fetching new data set. Please try again."),'error');
                success = false;
            }
            this.loader = false;
            return success;
        },
        async getMeasurements(from, to){/* device, list */
            try{
                /* if(typeof device === 'undefined'){
                    return (await Promise.all(
                        list.map((set)=>{
                            const params = {};
                            if(isset(()=>set[1]) && !!set[1].length){
                                params.devices = set[1].join(',');
                            }
                            if(isset(()=>set[2]) && !!set[2].length){
                                params.shipments = set[2].join(',');
                            }
                            if(isset(()=>set[3])){
                                params.operator = set[3];
                            }
                            return axios.get($getUrl('backendTelemetryUoM',{uomCode: set[0]},params));
                        })
                    )).reduce((a, e)=> a.concat(e.data.data), []);
                }
                else{
                    return (await axios.get($getUrl('backendTelemetryDevice',{deviceId: device}))).data;
                } */
                const params = {};
                if(typeof from !== 'undefined'){
                    params.date_from = from;
                }
                if(typeof to !== 'undefined'){
                    params.date_to = to;
                }
                return (await axios.get(this.getQueryUrl(params))).data;
            }
            catch(e){
                console.error(e);
            }
        },
        updateSlider(){
            this.sliderOptions = {
                start: this.currentXMin !== Number.POSITIVE_INFINITY && this.currentXMax !== Number.NEGATIVE_INFINITY?[this.currentXMin, this.currentXMax]:[Math.max(this.measurements.minimum_timestamp, this.measurements.maximum_timestamp - this.measurements.max_date_span), this.measurements.maximum_timestamp],
                connect: true,
                behaviour: "drag",
                step: 1,
                range: {
                    'min': this.measurements.minimum_timestamp,
                    'max': this.measurements.maximum_timestamp
                },
                limit: this.measurements.max_date_span
            };
        },
        async forceUpdate(){
            this.loader = true;
            if(this.timeRange[0] !== Number.POSITIVE_INFINITY && this.timeRange[1] !== Number.NEGATIVE_INFINITY){
                this.measurements = await this.getMeasurements(this.timeRange[0], this.timeRange[1]);
            }
            else{
                this.measurements = await this.getMeasurements();
            }
            this.loader = false;
        }
    },
    watch:{
        async modalDevice(){
            if(typeof this.modalDevice !== 'undefined'){
                this.modalDeviceSetsLoading = true;
                console.warn('todo');
                await axios.get($getUrl('backendDevicesSingle',{deviceId: this.modalDevice}))
                /*
                this.modalDeviceSets = (await axios.get($getUrl('backendDevicesSingle',{deviceId: this.modalDevice}))).data.measurements.map((m)=>({
                    text:   $trans(m.descr),
                    value:  m.code
                }));
                */
                this.modalDeviceSets = [{
                    "id": 1,
                    "code": "temperature",
                    "name": "°C",
                    "descr": "Temperature in ⁰C"
                },{
                    "id": 2,
                    "code": "humidity",
                    "name": "°C",
                    "descr": "Humidity"
                }].map((m)=>({
                    text:   $trans(m.descr),
                    value:  m.code
                }));
                this.modalDeviceSetsLoading = false;
            }
        },
        selected:{
            deep: true,
            handler: function(){
                if(!this.initial)
                    this.updateSelected();
            }
        },
        list:{
            deep: true,
            handler: function(){
                if(!this.initial)
                    this.updateSelected();
            }
        },
        rangeModalTimes:{
            deep: true,
            handler: function(){
                this.rangeModalTimesErrors = {
                    start:  undefined,
                    end:    undefined
                };
                const regex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
                if(regex.test(this.rangeModalTimes.start)){
                    if(isset(()=>this.rangeModalDates.start) && this.rangeModalTimes.start !== this.rangeModalDates.start.getHours().toString().padStart(2,'0')+':'+this.rangeModalDates.start.getMinutes().toString().padStart(2,'0')){
                        this.rangeModalDates.start.setHours(parseInt(this.rangeModalTimes.start.split(':')[0]));
                        this.rangeModalDates.start.setMinutes(parseInt(this.rangeModalTimes.start.split(':')[1]));
                        this.$set(this.rangeModalDates, 'start', new Date(this.rangeModalDates.start));
                    }
                }
                else{
                    this.rangeModalTimesErrors.start = $trans("Invalid time format! Enter data in HH:MM format!");
                }
                if(regex.test(this.rangeModalTimes.end)){
                    if(isset(()=>this.rangeModalDates.end) && this.rangeModalTimes.end !== this.rangeModalDates.end.getHours().toString().padStart(2,'0')+':'+this.rangeModalDates.end.getMinutes().toString().padStart(2,'0')){
                        this.rangeModalDates.end.setHours(parseInt(this.rangeModalTimes.end.split(':')[0]));
                        this.rangeModalDates.end.setMinutes(parseInt(this.rangeModalTimes.end.split(':')[1]));
                        this.$set(this.rangeModalDates, 'end', new Date(this.rangeModalDates.end));
                    }
                }
                else{
                    this.rangeModalTimesErrors.end = $trans("Invalid time format! Enter data in HH:MM format!");
                }
            }
        },
        rangeModalDates:{
            deep: true,
            handler: function(){
                this.rangeModalError = undefined;
                if(isset(()=>this.rangeModalDates.start)){
                    this.rangeModalTimes.start = this.rangeModalDates.start.getHours().toString().padStart(2,'0')+':'+this.rangeModalDates.start.getMinutes().toString().padStart(2,'0')
                }
                if(isset(()=>this.rangeModalDates.end)){
                    this.rangeModalTimes.end = this.rangeModalDates.end.getHours().toString().padStart(2,'0')+':'+this.rangeModalDates.end.getMinutes().toString().padStart(2,'0')
                }
                if(isset(()=>this.rangeModalDates.start) && isset(()=>this.rangeModalDates.end)){
                    const span = (this.rangeModalDates.end.getTime() - this.rangeModalDates.start.getTime())/1000;
                    if(span > this.measurements.max_date_span){
                        this.rangeModalError = $trans("Range you selected is too big! Please select different dates and try again!");
                    }
                    else if(span === 0){
                        if(this.rangeModalDates.end.getHours() === 0){
                            if(moment().isSame(this.rangeModalDates.end, 'day')){
                                //today
                                this.rangeModalTimes.end = new Date().getHours().toString().padStart(2,"0") + ":" + new Date().getMinutes().toString().padStart(2,"0");
                            }
                            else{
                                //another day
                                this.rangeModalTimes.end = "23:59";
                            }
                        }
                        else{
                            this.rangeModalError = $trans("Start datetime is equal to end datetime! Please select two different dates and try again!");
                        }
                    }
                }
            }
        },
        timeRange:{
            deep: true,
            handler: async function(){
                if(!(this.timeRange[0] >= this.measurements.date_from && this.timeRange[1] <= this.measurements.date_to)){
                    const wereAllSelected = this.selectedCount === this.measurements.data.length;
                    this.measurements = await this.getMeasurements(this.timeRange[0], this.timeRange[1]);
                    if(wereAllSelected){
                        this.selectAll();
                    }
                }
            }
        },
        'measurements.minimum_timestamp': function(){
            this.updateSlider();
        },
        'measurements.maximum_timestamp': function(){
            this.updateSlider();
        },
        'measurements.max_date_span': function(){
            this.updateSlider();
        }
    }
}
</script>