<template>
    <inner-icon-component :icon="realIcon" :size="size" :wrap="wrap" :slash="slash"></inner-icon-component>
</template>
<script>
import innerIconComponent from './../components/components/icon-component.vue';

export default {
    name:'iconComponent',
    components:{
        innerIconComponent
    },
    props:{
        icon:{
            type: [String, Boolean],
            default: 'fas fa-question'
        },
        size:{
            type: String,
            default: 'S'
        },
        deviceType:{
            type: String,
            default: undefined
        },
        wrap:{
            type: Boolean,
            default: true,
        },
        slash:{
            type: Boolean,
            default: false,
        }
    },
    computed:{
        realIcon(){
            return window.getIfIsset(()=>this.deviceIcon, this.icon)
        },
        deviceIcon(){
            if(typeof this.deviceType !== "undefined"){
                return window.getIfIsset(()=>window.deviceIconsLUT[this.deviceType], 'fas fa-microchip')
            }
            return undefined
        }
    }
}
</script>