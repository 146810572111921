//vue
import Vue from "vue";
window.Vue = Vue;
import init_custom_comps from "./components/imports"
init_custom_comps(window.Vue, ['icon-component'])

//our CSS file
import "./vayaplatform.scss";
//fontawesome
import "@fortawesome/fontawesome-free/js/all.min.js";
//leaflet
import L from "leaflet";
import "leaflet.markercluster";
import "./leaflet.fontawesomemarkers";
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: marker,
    iconRetinaUrl: marker2x,
    shadowUrl: markerShadow,
});
L.Control.MarkerCenter = L.Control.extend({
    options: {
        position: 'topright',
        markerPos: undefined
    },
    onAdd: function(map) {
        const div = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        const a = L.DomUtil.create('a', undefined, div);
        a.href = "#";
        L.DomEvent.disableClickPropagation(a);
        L.DomEvent.on(a, 'click', L.DomEvent.stop);
        L.DomEvent.on(a, 'click', this._onClick, this);
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.style = "height: 1.4em; vertical-align: -0.4em;"
        svg.setAttribute("viewBox","0 0 384 512");
        const icon = document.createElementNS("http://www.w3.org/2000/svg", "path");
        icon.setAttribute("d", "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z");
        svg.appendChild(icon);
        a.appendChild(svg);
        return div;
    },
    _onClick: function(e) {
        this._map.panTo(this.options.markerPos);
    }
});
L.control.markerCenter = function(opts) {
    return new L.Control.MarkerCenter(opts);
}
window.L = L;
//moment
import moment from "moment";
import "moment/locale/pl";
window.moment = moment;
//isset
window.safePromise = async function(promise){
    try{
        return (await promise);
    }
    catch(e){}
    return false;
}
//axios
import axios from "axios";
axios.interceptors.request.use(async function (config) {
    config.headers['device-fingerprint'] = await window.getFingerprint();
    if(config.method === 'post' && config.url.startsWith('/')){
        config.headers['X-CSRFToken'] = window.getCookie('csrftoken');
    }
    return config;
});
window.axios = axios;

//chartjs
import {Line} from "vue-chartjs/legacy";
import 'chart.js/auto';
import 'chartjs-adapter-moment';

//vue async computed
import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);
//vue dialog
import VuejsDialog from "./components/vuejs-dialog/dist/vuejs-dialog.min.js";
import alertDialogComponent from './custom-components/alert-dialog-component.vue'
Vue.use(VuejsDialog,{
    view:           'alert-dialog-component',
    okText:         window.$trans('OK'),
    cancelText:     window.$trans('Cancel'),
    backdropClose:  true,
});
Vue.dialog.registerComponent('alert-dialog-component',alertDialogComponent)
//bulma calendar
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
    screens: {
        tablet: '769px',
        desktop: '1024px',
    },
    datePicker:{
        popover:{
            visibility: 'click',
        }
    },
    theme:{
        color: 'grey',
        contentAccent:{
            light: 'vc-font-bold vc-text-white-200',
        },
        bgLow:{
            light: 'vc-bg-{color}-400 vc-border-2 vc-border-{color}-700',
        },
    }
});

//Tippy
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy, {
    directive:  "has-tooltip",
    arrow:      true,
    delay:      125,
});
Vue.component("tooltip-component", TippyComponent);

//fingerprint
import Fingerprint2 from "fingerprintjs2";
window.Fingerprint2 = Fingerprint2;
window.cachedFingerprint = undefined;
window.getFingerprint = function(){
    if(typeof window.cachedFingerprint !== 'undefined'){
        return window.cachedFingerprint;
    }
    const ret = new Promise((resolve)=>{
        const obtainFprint = ()=>{
            Fingerprint2.get(function (components) {
                window.cachedFingerprint = Fingerprint2.x64hash128(components.map(function (component) { return component.value }).join(''), 31);
                resolve(window.cachedFingerprint);
            })
        }
        if (window.requestIdleCallback) {
            requestIdleCallback(obtainFprint)
        } else {
            setTimeout(obtainFprint, 500)
        }
    })
    window.cachedFingerprint = ret;
    return ret;
}
//Bowser
import Bowser from "bowser";
window.cachedBrowserData = undefined;
window.browserData = ()=>{
    if(typeof window.cachedBrowserData === 'undefined'){
        window.cachedBrowserData = Bowser.parse(window.navigator.userAgent);
    }
    return window.cachedBrowserData;
}
window.getBrowserName = ()=>`${window.browserData().browser.name} - ${window.browserData().os.name} ${window.browserData().os.versionName}`;
//Jdenticon
import jdenticon from "jdenticon";
window.jdenticon = jdenticon;

import jdenticonComponent from './custom-components/jdenticon-component.vue'
Vue.component('jdenticon-component',jdenticonComponent)

//noUiSlider
import noUiSlider from "nouislider";
import 'nouislider/distribute/nouislider.css';
window.noUiSlider = noUiSlider;
import sliderComponent from './custom-components/slider-component.vue'
Vue.component('slider-component',sliderComponent)

import alertComponent from './custom-components/alert-component.vue'
Vue.component('alert-component',alertComponent)

import dynamicCardComponent from './custom-components/dynamic-card-component.vue'
Vue.component('dynamic-card-component',dynamicCardComponent)

import dynamicContentContainer from './custom-components/dynamic-content-container.vue'
Vue.component('dynamic-content-container',dynamicContentContainer)

import dashboardComponent from './custom-components/dashboard-component.vue'
Vue.component('dashboard-component',dashboardComponent)

import breadcrumbComponent from './custom-components/breadcrumb-component.vue'
Vue.component('breadcrumb-component',breadcrumbComponent)

import calendarComponent from './custom-components/calendar-component.vue'
Vue.component('calendar-component',calendarComponent)

import calendarButtonComponent from './custom-components/calendar-button-component.vue'
Vue.component('calendar-button-component',calendarButtonComponent)

import statusComponent from './custom-components/status-component.vue'
Vue.component('status-component',statusComponent)

import measurementsChart from './custom-components/measurements-chart.vue'
Vue.component('measurements-chart',measurementsChart)

import measurementsComponent from './custom-components/measurements-component.vue'
Vue.component('measurements-component',measurementsComponent)

import mapComponentBare from './custom-components/map-component-bare.vue'
Vue.component('map-component-bare',mapComponentBare)
import mapComponent from './custom-components/map-component.vue'
Vue.component('map-component',mapComponent)

import batteryIndicatorComponent from './custom-components/battery-indicator-component.vue'
Vue.component('battery-indicator-component',batteryIndicatorComponent)

import configurationComponent from './custom-components/configuration-component.vue'
Vue.component('configuration-component',configurationComponent)

import eventDetails from './custom-components/event-details.vue'
Vue.component('event-details',eventDetails)

window.deviceIconsLUT = {
    'mbs':         'fas fa-broadcast-tower',
    'seal_v1':     'fas fa-lock',
    'obd2':        'fas fa-tachometer-alt',
    'aqs':         'fas fa-wind'
}

import iconComponent from './custom-components/icon-component.vue'
Vue.component('icon-component',iconComponent)



Vue.component('line-chart',Line)