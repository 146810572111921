<template>
    <div class="dropdown" v-bind:class="{'is-active':show, 'is-up':isUp}">
        <div class="dropdown-trigger" v-on:click.stop="toggleShow" style="cursor: pointer;" ref="dropdownElement">
            <component :is="triggerComponent" v-bind:class="{'button': triggerComponent === 'button', 'has-icons-right':showArrow, ...buttonClass}" ref="button" aria-haspopup="true" aria-controls="dropdown-menu" :disabled="disabled">
                <slot name="button"></slot>
                <icon-component v-if="showArrow" :icon="show?'fas fa-angle-up':'fas fa-angle-down'" class="is-right"></icon-component>
            </component>
        </div>
        <div class="dropdown-menu" ref="dropdownMenu" role="menu">
            <div class="dropdown-content" ref="content">
                <slot>
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
import iconComponent from './icon-component.vue';

export default {
    name:'dropdownComponent',
    props:{
        closeOnSelect:{
            type: Boolean,
            default: true
        },
        buttonClass:{
            type: [String, Object, Array],
            default: ''
        },
        triggerComponent:{
            type: String,
            default: 'button',
        },
        showArrow:{
            type: Boolean,
            default: true,
        },
        disabled:{
            type: Boolean,
            default: false
        }
    },
    components: {iconComponent},
    methods:{
        toggleShow(){
            if(!this.disabled)
                this.show = !this.show;
        },
        outsideClick(e){
            const path = getPath(e);
            //ignore direct click
            if(path.includes(this.$refs.button)) return;
            //ignore inner clicks, if prop says so
            if(!this.closeOnSelect && path.includes(this.$refs.content)) return;

            document.removeEventListener('click',this.outsideClick, true);
            this.show = false;
        }
    },
    data(){
        return{
            show: false,
            isUp: false,
        }
    },
    watch:{
        show(){
            if(this.show){
                document.addEventListener('click',this.outsideClick, true);
                this.$nextTick(()=>{
                    if(this.$refs.dropdownElement.getBoundingClientRect().bottom + this.$refs.dropdownMenu.scrollHeight + 10 > window.innerHeight && this.$refs.dropdownElement.getBoundingClientRect().top - this.$refs.dropdownMenu.scrollHeight - 10 > 0){
                        //no place under the component, but there is place above - swap
                        this.isUp = true;
                    }
                });
            }
            else{
                document.removeEventListener('click',this.outsideClick, true);
                this.isUp = false;
            }
        }
    }
}
</script>