<template>
    <map-component-bare ref="map" @ready="init" @position="debounceGetData" :width="width" :height="height" :scroll-to-zoom="scrollToZoom" :tooltip-target="tooltipTarget" :tooltip-data="tooltipData" :tooltip-theme="tooltipTheme" :loader-active="loaderActive"></map-component-bare>
</template>
<script>
module.exports = {
    props:{
        width:{
            type: String,
            default: '100%',
        },
        height:{
            type: String,
            default: '100vh',
        },
        queryLimit:{
            type: Number,
            default: 100,
        },
        shipment:{
            type: Object,
            default: undefined
        },
        refresh:{
            type: Number,
            default: 0
        },
        scrollToZoom:{
            type: Boolean,
            default: false
        },
        measurements:{
            type: Array,
            default: undefined
        },
        measurements_colors:{
            type: Array,
            default: ()=>[]
        }
    },
    data(){
        return {
            markers:            undefined,
            loaderActive:       true,
            tooltipTarget:      undefined,
            tooltipData:        '',
            debounceGetData:    debounce(async ()=>{
                if(typeof this.shipment === 'undefined' && typeof this.measurements === 'undefined'){
                    await waitUntil(()=>this.loaderActive == false);
                    this.getData();
                }
                if(!this.noEmit){
                    this.$emit('new-position', this.$refs.map.getCurrentView());
                }
            },250),
            showToast:          true,
            markerPath:         undefined,
            refreshInterval:    undefined,
            noEmit:             false,
            tooltipTheme:       'dark'
        };
    },
    methods:{
        init: function(){
            if(typeof this.refreshInterval !== 'undefined'){
                clearInterval(this.refreshInterval);
                this.refreshInterval = undefined;
            }
            if(typeof this.shipment !== 'undefined'){
                //single shipment history
                this.openTruckDetails();
                if(!!this.refresh){
                    this.refreshInterval = setInterval(this.openTruckDetails, this.refresh*1000);
                }
            }
            else if(typeof this.measurements !== 'undefined'){
                //map as chart
                this.loaderActive = true;
                this.clearMap();
                this.markerPath = L.layerGroup();
                var resultBounds = undefined;
                var i = 0;
                for(const measurement of this.measurements){
                    if(!!measurement[0].measurements.length){
                        const ret = this.drawHistory(this.markerPath, measurement[0].measurements.map((e)=>({lat: e.value.lat, lng: e.value.lng, timestamp: e.timestamp})), this.measurements_colors[i]);
                        if(typeof resultBounds === 'undefined'){
                            resultBounds = ret;
                        }
                        else{
                            resultBounds = resultBounds.extend(ret);
                        }
                    }
                    i = i++;
                }
                this.map.addLayer(this.markerPath);
                if(typeof resultBounds !== 'undefined'){
                    this.map.fitBounds(resultBounds);
                }
                this.loaderActive = false;
            }
            else{
                //generic view - all shipments
                this.getData();
                if(!!this.refresh){
                    this.refreshInterval = setInterval(this.getData, this.refresh*1000);
                }
            }
        },

        markerClicked: function(event, marker){
            this.$emit('marker-click', {event:event, marker:marker});
            event.originalEvent.preventDefault();
            return false;
        },

        openTruckDetails: async function(){
            if(typeof this.refreshInterval === 'undefined'){
                this.loaderActive = true;
                this.clearMap();
            }
            
            try{
                var details = (await axios.get($getUrl('backendShipmentsSingleMapData',{shipmentId: this.shipment.id}))).data;
            }
            catch(error){
                console.error(error);
            }
            //TODO - handle empty response and errors
            this.loaderActive = true;

            this.clearMap();
            this.markers = L.markerClusterGroup();
            this.map.addLayer(this.markers);

            try{
                //sender location geometry
                var sender_location = this.$refs.map.drawGeoJSON(undefined, details.sender.location.geometry, details.sender.mine, this.markers, details.sender.mine?$getUrl('frontend:my_company:details'):$getUrl('frontend:companies:single',{companyId: details.sender.id}));
                this.bindElementTooltip(sender_location, `<b>${details.sender.name}</b>`, details.sender.mine);
            }
            catch(error){
                console.error("Error occured while drawing sender location");
                console.error(error);
            }

            try{
                //receiver location geometry
                var receiver_location = this.$refs.map.drawGeoJSON(undefined, details.receiver.location.geometry, details.receiver.mine, this.markers, details.receiver.mine?$getUrl('frontend:my_company:details'):$getUrl('frontend:companies:single',{companyId: details.receiver.id}));
                this.bindElementTooltip(receiver_location, `<b>${details.receiver.name}</b>`, details.receiver.mine);
            }
            catch(error){
                console.error("Error occured while drawing receiver location");
                console.error(error);
            }

            var bounds = undefined;
            try{
                //shipment position history
                const path = details.shipment_locations.map((e)=>({lat:e.value.lat, lng:e.value.lng, timestamp: e.timestamp, accuracy:e.value.accuracy}));
                this.markerPath = L.layerGroup();
                bounds = this.drawHistory(this.markerPath, path.reverse(), details.sender.mine, true);
                this.map.addLayer(this.markerPath);
            }
            catch(error){
                console.error("Error occured while drawing history");
                console.error(error);
            }
            
            if(typeof bounds !== 'undefined'){
                this.noEmit = true;
                this.map.fitBounds(bounds.pad(0.05));
                setTimeout(()=>{
                    this.noEmit = false;
                },1000);
            }
            this.loaderActive = false;
        },

        clearMap(preserveLayers){
            if(typeof this.markerPath !== 'undefined'){
                this.markerPath.remove();
                this.markerPath = undefined;
            }
            if(typeof this.markers !== 'undefined'){
                if(Array.isArray(preserveLayers) && !!preserveLayers.length){
                    this.markers.eachLayer((layer)=>{
                        var pos = preserveLayers.indexOf(getIfIsset(()=>layer.options.layerId,null));
                        if(pos === -1){
                            this.markers.removeLayer(layer);
                        }
                        else{
                            preserveLayers.splice(pos, 1);
                        }
                    });
                }
                else{
                    this.markers.remove();
                    this.markers = undefined;
                }
            }
            //cleanup empty <a> elements
            const svg = getIfIsset(()=>this.map.getPane('overlayPane').getElementsByTagName('svg')[0], undefined);
            if(typeof svg !== 'undefined'){
                for(const a of svg.getElementsByTagName('a')){
                    if(a.childElementCount === 0){
                        a.remove();
                    }
                }
            }
            return preserveLayers;
        },

        drawHistory(layer, path, mineOrColor, drawCurrentMarker){
            var className = undefined;
            if(mineOrColor === true || mineOrColor === false){
                className = mineOrColor?'mine':'foreign';
            }
            const lineOpts = typeof className !== 'undefined' ? {weight: 2, className: 'is-history-path-'+className, interactive: false} : {weight: 2, color: mineOrColor, interactive: false};
            const pointsOpts = typeof className !== 'undefined' ? {radius: 3, className: 'is-history-path-'+className} : {radius: 3, color: mineOrColor};
            const line = L.polyline(path, lineOpts);
            layer.addLayer(line);
            path.map((p)=>{
                var point;
                if(isset(()=>p.accuracy)){
                    point = L.circle(p, Object.assign({}, pointsOpts, {radius: p.accuracy}));
                    this.bindElementTooltip(point, `<b>${renderTimestamp(p.timestamp, "SHORT_DATETIME_FORMAT")}</b><br><i>Accuracy: ${p.accuracy} meters</i>`, mineOrColor);
                }
                else{
                    point = L.circleMarker(p, pointsOpts);
                    this.bindElementTooltip(point, `<b>${renderTimestamp(p.timestamp, "SHORT_DATETIME_FORMAT")}</b>`, mineOrColor);
                }
                layer.addLayer(point);
            });
            if(drawCurrentMarker === true){
                const last_position = path[0];
                var currentMarker = this.createMarker(undefined, last_position, 'shipment', undefined, mineOrColor);
                this.bindElementTooltip(currentMarker, `<b>${renderTimestamp(last_position.timestamp, "SHORT_DATETIME_FORMAT")}</b>`, mineOrColor);
                layer.addLayer(currentMarker);
            }
            return line.getBounds();
        },

        getData: async function(){
            var map_data = (await axios($getUrl('backendMapData',undefined,{...this.$refs.map.getCurrentViewBounds(0.05), limit:this.queryLimit, zoom: this.map.getZoom()}))).data;
            if(map_data.count >= this.queryLimit && this.showToast){
                this.showToast = false;
                toast($trans("Current view is not able to show all %(total_count)s points, only first %(displayed_count)s are shown!",{total_count: map_data.count, displayed_count: map_data.data.length}),'warning')
                .then(()=>{
                    this.showToast = true;
                });
            }

            this.loaderActive = true;
            var newLayersIds = [];
            for(const point of map_data.data){
                var id = undefined;
                if(point.geometry !== null){
                    id = `${point.type}-${point.id}-geometry`;
                }
                else{
                    id = `${point.type}-${point.id}-marker`;
                }
                point.layerId = id;
                newLayersIds.push(id);
            }

            var layersToAdd = this.clearMap(newLayersIds);
            if(typeof layersToAdd === 'undefined'){
                layersToAdd = newLayersIds;
            }
            if(typeof this.markers === 'undefined'){
                this.markers = L.markerClusterGroup();
                this.map.addLayer(this.markers);
            }

            for(const point of map_data.data.filter((p)=>layersToAdd.includes(p.layerId))){
                var marker = undefined;
                if(point.geometry !== null){
                    marker = this.$refs.map.drawGeoJSON(point.layerId, point.geometry, point.mine, this.markers, point.url);
                }
                else{
                    marker = this.createMarker(point.layerId, [point.lat, point.lng], point.type, point.url, point.airquality.quality_int);
                    this.markers.addLayer(marker);
                }
                this.bindElementTooltip(marker, `<b>${point.title}</b><br>Air quality: <b>${point.airquality.quality}</b>`, point.airquality.quality_int, point);
            }

            this.loaderActive = false;
        },

        createMarker(id, position, type, link, airquality){
            if(typeof mine === 'undefined'){
                mine = true;
            }
            var icon = undefined;
            switch(type){
                case 'shipment': icon = 'truck'; break;
                case 'location': icon = 'warehouse'; break;
                case 'device:aqs': icon = 'wind'; break;
            }
            return new this.$refs.map.customMarker(position,{
                icon: L.AwesomeMarkers.icon({
                    pulse:          false,//todo
                    href:           typeof link === 'undefined' ? '#' : link,
                    icon,
                    markerColor:    'aqs-'+airquality
                }),
                layerId: id
            });
        },

        markerHover(dir, data, element, airquality){
            if(dir === 'in'){
                this.tooltipData = data;
                if(this.tooltipTarget !== element){
                    this.tooltipTarget = element;
                    this.tooltipTheme = 'is-tooltip-aqs-'+airquality
                    this.$nextTick(()=>{
                        this.tooltip.init();
                        this.tooltip.tip.show();
                    });
                }
                else{
                    this.tooltip.tip.show();
                }
            }
            else{
                this.tooltip.tip.hide();
            }
        },

        setCurrentView(view, animate = false){
            if(JSON.stringify(view) !== JSON.stringify(this.$refs.map.getCurrentView())){
                this.noEmit = true;
                this.$refs.map.setCurrentView(view, animate);
                this.debounceGetData();
                setTimeout(()=>{
                    this.noEmit = false;
                },1000);
            }
        },

        bindElementTooltip(element, tooltipContent, tooltipAirquality, clickData){
            element.on('mouseover', (e)=>this.markerHover('in', tooltipContent, getIfIsset(()=>e.target._icon, getIfIsset(()=>e.target._path, e.originalEvent.path[0])), tooltipAirquality));
            element.on('mouseout', ()=>this.markerHover('out'));
            if(typeof clickData !== 'undefined'){
                element.on('click', (e)=>this.markerClicked(e, clickData));
            }
        }
    },
    watch:{
        shipment:{
            deep: true,
            handler(){
                this.init();
            }
        },
        measurements:{
            deep: true,
            handler(){
                this.init();
            }
        },
        measurements_colors:{
            deep: true,
            handler(){
                this.init();
            }
        }
    },
    computed:{
        map(){
            return this.$refs.map.map;
        },
        tooltip(){
            return this.$refs.map.$refs.tooltip;
        }
    }
}
</script>
<style scoped>
#zoom-in-button {
    position: absolute;
    right: 5px;
    bottom: 75px;
}

#zoom-out-button {
    position: absolute;
    bottom: 25px;
    right: 5px;
}
</style>