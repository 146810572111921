var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("map-component-bare", {
    ref: "map",
    attrs: {
      width: _vm.width,
      height: _vm.height,
      "scroll-to-zoom": _vm.scrollToZoom,
      "tooltip-target": _vm.tooltipTarget,
      "tooltip-data": _vm.tooltipData,
      "tooltip-theme": _vm.tooltipTheme,
      "loader-active": _vm.loaderActive,
    },
    on: { ready: _vm.init, position: _vm.debounceGetData },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }