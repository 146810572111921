<template>
    <div>
        <modal-component v-if="typeof summary !== 'undefined' && summary.status >= 300" @close="summary = undefined" :is-card="true" :title="$trans('Wystąpił błąd podczas importu danych')">
            <template v-if="Array.isArray(summary.data) && summary.data.length === data.length">
                <label class="label">{{$trans('Rekordów zawierających błędy:')}} {{erroredRows}}</label>
                <div class="columns">
                    <div class="column">
                        <label class="label">{{$trans('Poprawnych rekordów:')}} {{data.length - erroredRows}}</label>
                    </div>
                    <div class="column" v-if="data.length - erroredRows">
                        <button class="button is-fullwidth is-success is-small" :disabled="(data.length - erroredRows) === 0" @click="skipErrors">
                            {{$trans('Ignoruj błędy, zaimportuj %(count)s poprawnych rekordów', {count: data.length - erroredRows})}}
                        </button>
                    </div>
                </div>
                <div class="columns is-vcentered">
                    <div class="column is-narrow">
                        <label class="label">{{$trans('Pokaż rekordy:')}}</label>
                    </div>
                    <div class="column">
                        <input-component :options="[{text:$trans('Wszystkie'),value:0},{text:$trans('Tylko błędne'),value:1},{text:$trans('Tylko poprawne'),value:2}]" type="select" v-model="rowsFilter"></input-component>
                    </div>
                </div>
                
                <table-component :searchable="false" :url_params="false" :pagination_informations="false" :columns="errorsColumns()" :data="errorsData"></table-component>

                <h2 class="subtitle">{{$trans('Popraw powyższe błędy i spróbuj ponownie')}}</h2>
            </template>
            <template v-else>
                <h2 class="subtitle">{{$trans('Wystąpił nierozpoznany błąd, spróbuj ponownie')}}</h2>
            </template>
            <template v-slot:footer>
                <button class="button is-fullwidth is-danger" @click="summary = undefined">
                    {{$trans('Zamknij')}}
                </button>
            </template>
        </modal-component>
        <modal-component v-if="typeof summary !== 'undefined' && summary.status === 200" @close="summary = undefined" :is-card="true" :title="$trans('Poprawny import danych')">
            <label class="label">{{$trans('Zaimportowanych rekordów:')}} {{summary.data.count}}</label>
            <table-component :searchable="false" :url_params="false" :pagination_informations="false" :columns="columns" :data="data"></table-component>
            <template v-slot:footer>
                <a :href="redirectUrl" class="button is-fullwidth is-success">
                    {{$trans('Zamknij')}}
                </a>
            </template>
        </modal-component>

        <loader-component :show="loader"></loader-component>
        <csv-import-component ref="csv" v-bind="csvSettings" @data="(e)=>data = e" @columns="(e)=>columns = translateColumns(e)"></csv-import-component>
        <button class="button is-fullwidth is-primary" :disabled="!Array.isArray(data) || data.length === 0" @click="save">
            <icon-component icon="fas fa-save"></icon-component>
            <span>{{$trans('Importuj')}}</span>
        </button>
    </div>
</template>
<script>
import csvImportComponent from './csv-import-component.vue'
import modalComponent from './modal-component.vue'
import loaderComponent from './loader-component.vue'
import iconComponent from './icon-component.vue'
import tableComponent from './table-component.vue'
import inputComponent from './input-component.vue'
export default {
    name:'batchImportComponent',
    components: { csvImportComponent, modalComponent, loaderComponent, iconComponent, tableComponent, inputComponent },
    props:{
        csvSettings:{
            type:       Object,
            required:   true
        },
        postUrl:{
            type:       String,
            required:   true
        },
        redirectUrl:{
            type:       String,
            required:   true
        }
    },
    data(){
        return {
            loader:     false,
            data:       undefined,
            columns:    undefined,
            summary:    undefined,
            rowsFilter: 1
        }
    },
    methods:{
        async save(){
            this.loader = true
            this.summary = undefined
            try{
                let res = await axios.post(this.postUrl,this.data)
                this.summary = res
            }
            catch(e){
                console.error("Error while saving object", e)
                if(window.isset(()=>e.response.data) && window.isset(()=>e.response.status)){
                    this.summary = e.response
                    this.rowsFilter = 1
                }
                else{
                    toast($trans('Wystąpił nierozpoznany błąd! Spróbuj ponownie.'),'error')
                }
            }
            finally{
                this.loader = false
            }
        },
        skipErrors(){
            this.data = this.data.filter((e, i) => window.getIfIsset(()=>this.summary.data[i]) === null)
            this.save()
        },
        errorsColumns(){
            return [{
                label: $trans('Nr. wiersza'),
                field: '_row_index',
                class: 'is-narrow'
            }].concat(this.columns.map((column)=>{
                column.renderFn = (row, settings)=>{
                    const column_error = window.getIfIsset(()=>row._errors[settings.column.field])
                    if(typeof column_error !== 'undefined'){
                        return `${row[settings.column.field]}<p class="help is-danger">${column_error}</p>`    
                    }
                    return row[settings.column.field]
                }
                return column
            }).concat({
                label: '',
                renderFn: (row)=>{
                    const renderedColumns = this.columns.map((column) => column.field)
                    const leftoverErrors = typeof row._errors === 'string' ? [[$trans('Błąd'),row._errors]] : Object.entries(row._errors === null ? {} : row._errors).filter((error)=>!renderedColumns.includes(error[0]))
                    let ret = ''
                    for(const error of leftoverErrors){
                        ret += `<p class="help is-danger">${error[0]} - ${error[1]}</p>`
                    }
                    return ret
                },
                class: 'is-narrow'
            }))
        },
        translateColumns(columns){
            return columns.map((column)=>({
                label:  column.text,
                field:  column.value
            }))
        }
    },
    computed:{
        erroredRows(){
            const data = window.getIfIsset(()=>this.summary.data)
            if(Array.isArray(data)){
                return data.filter((e) => e !== null).length
            }
            return 0
        },
        errorsData(){
            return (Array.isArray(this.data) ? this.data : []).map((row, row_index)=>{
                row._row_index = row_index+1+(this.$refs.csv.importDataHasHeader?1:0)
                row._errors = window.getIfIsset(()=>this.summary.data[row_index],null)
                row._valid = row._errors === null
                return row
            }).filter((row) => this.rowsFilter === 0 || (this.rowsFilter === 1 && row._valid === false) || (this.rowsFilter === 2 && row._valid === true))
        }
    }
}
</script>